<template>
  <div class="nest_header_box jcsb">
    <div class="left">
      <!-- @click="$router.push('/observe/home')" -->
      <div class="title" @click="goHome">
        <!-- <img alt="" src="~@/assets/img/observe-shi-logo.png" /> -->
        <!-- <span>联视 ● 视频墙</span> -->
        <img alt="" src="~@/assets/img/observe-shi-logo.png" />
        <!-- <span>飞行监控</span> -->
        <span>无人机调度中心</span>
      </div>
      <div class="menu" v-show="false">
        <ul class="ul">
          <template v-for="item in menuData">
            <li class="tap jin" :key="item.id" v-if="$route.path == item.url">
              <span>{{ item.title + "" }}</span>
            </li>
            <li class="tap" :key="item.id" @click="$router.push(item.url)" v-else>
              <span>{{ item.title }}</span>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <div class="right">
      <tianqi class="tianqi"></tianqi>
    </div>
  </div>
</template>

<script>
import tianqi from "@/pages/observe/home/components/header/tianqi";
import API from "@/api";
import { mapGetters } from "vuex";
export default {
  components: { tianqi },
  props: {
    uavBattery: {
      type: Object,
    },
    nestData: {
      type: Object,
    },

    uavData: {
      type: Object,
      default: () => ({}),
    },
  },
  inject: ["g_weather"],
  data() {
    return {
      menuData: [
        { id: 1, title: "无人机应用", url: "/fckernel?page=1" },
        { id: 2, title: "机巢应用", url: "/fckernel?page=2" },
        { id: 3, title: "视频墙", url: "/fckernelVideos" },
      ],
      mode: "离线状态",
      typeNet: 0,
      weatherIcon: null,
      now: null,
      curr_val: 0,
      parachuteStateList: [
        {
          icon: "jianglasanzhengchang",
          title: "降落伞进入警戒",
        },
        {
          icon: "jinrujingjie",
          title: "降落伞进入警戒",
        },
        {
          icon: "tuichujingjie",
          title: "降落伞退出警戒",
        },
        {
          icon: "kaisan",
          title: "降落伞开伞",
        },
      ],

      parachuteList: [
        {
          icon: "jianglasanzhengchang",
          title: "正常",
        },
        {
          icon: "jianglasanweiqiyong",
          title: "未启用",
        },
        {
          icon: "weizaixian",
          title: "控制器不在线",
        },
        {
          icon: "jianglasansanbaoweilianjie",
          title: "伞包未连接",
        },
        {
          icon: "jianglasanguzhang",
          title: "降落伞故障",
        },
      ],
      remote: {
        0: "yaokongqi-duankailianjie",
        1: "yaokongqiliang",
      },
      week_map: {
        0: "天",
        1: "一",
        2: "二",
        3: "三",
        4: "四",
        5: "五",
        6: "六",
      },
      ws: null,
      week_map: {
        0: "天",
        1: "一",
        2: "二",
        3: "三",
        4: "四",
        5: "五",
        6: "六",
      },
      fire: {
        0: "yanganan",
        1: "yanganhong",
        2: "yanganliang",
      },
      water: {
        0: "shuijinan",
        1: "shuijinhong",
        2: "sfhuijinliang",
      },
      power: {
        1: "dianliangbaifenzhiershi",
        2: "dianliangbaifenzhisishi",
        3: "dianliangbaifenzhiliushi",
        4: "dianliangbaifenzhibashi",
        5: "chongdianmoshi5",
      },
      weather: {},
      weather_data: {
        weather: "晴",
        icon: require(`@/assets/images/weather/icon/day/01.png`),
        temperature: 0,
        humidity: 0,
        windPower: "",
        windSpeed: "",
        minTemp: "",
        maxTemp: "",
        windDirection: "东北",
      },
      // <el-option label="1.4G" :value="2"> </el-option>
      //     <!-- 公网  -->
      //     <el-option label="4G/5G" :value="3"> </el-option>
      //     <el-option label="自动" :value="0"> </el-option>
      ModeStatusList: [
        { id: 2, data: "1.4G", label: "专网" },
        { id: 3, data: "4G/5G", label: "公网" },
        { id: 0, data: "自动", label: "自动" },
      ],
      zwArr: [
        {
          icon: "xinhaoshaixuan",
          info: "暂无",
        },
        {
          icon: "a-14-6",
          info: "覆盖非常好",
        },
        {
          icon: "a-14-5",
          info: "覆盖好,室外能够发起各种业务,可获得高等数据业务,室内能够发起各种业务，可获得中等数据业务",
        },
        {
          icon: "a-14-4",
          info: "覆盖较好,室外能够发起各种业务,可获得中等数据业务,室内能够发起各种业务，可获得低数据业务",
        },
        {
          icon: "a-14-3",
          info: "覆盖一般，室外能够发起各种业务，可获得中等数据业务,室内呼叫成功率低",
        },
        {
          icon: "a-14-2",
          info: "覆盖率差，户外语音业务能够起呼，但成功率较低",
        },
        {
          icon: "a-14-1",
          info: "覆盖较差，业务基本无法起呼",
        },
      ],
      gwArr: [
        {
          icon: "xinhaoshaixuan",
          info: "暂无",
        },
        {
          icon: "a-5g-6",
          info: "覆盖非常好",
        },
        {
          icon: "a-5g-5",
          info: "覆盖好,室外能够发起各种业务,可获得高等数据业务,室内能够发起各种业务，可获得中等数据业务",
        },
        {
          icon: "a-5g-4",
          info: "覆盖较好,室外能够发起各种业务,可获得中等数据业务,室内能够发起各种业务，可获得低数据业务",
        },
        {
          icon: "a-5g-3",
          info: "覆盖一般，室外能够发起各种业务，可获得中等数据业务,室内呼叫成功率低",
        },
        {
          icon: "a-5g-2",
          info: "覆盖率差，户外语音业务能够起呼，但成功率较低",
        },
        {
          icon: "a-5g-1",
          info: "覆盖较差，业务基本无法起呼",
        },
      ],
      modeList: [
        { id: 1, data: "MANUAL", label: "手动模式", disabled: false },
        { id: 2, data: "ALTITUDE", label: "定高模式", disabled: false },
        { id: 3, data: "POSITION", label: "GPS模式", disabled: false },
        { id: 4, data: "AUTO_MISSION", label: "航线模式", disabled: false },
        { id: 5, data: "ACRO", label: "特技模式", disabled: true },
        { id: 6, data: "OFF_BOARD", label: "版外模式", disabled: true },
        { id: 7, data: "STABILIZED", label: "自稳模式", disabled: true },
        { id: 8, data: "HOLD", label: "定点模式", disabled: false },
        { id: 9, data: "LAND", label: "降落模式", disabled: false },
        { id: 10, data: "TAKE_OFF", label: "起飞模式", disabled: true },
        { id: 11, data: "AUTO_RTL", label: "返航模式", disabled: false },
        {
          id: 12,
          data: "AUTO_FOLLOW_TARGET",
          label: "自动跟随模式",
          disabled: true,
        },
      ],

      signal: {
        1: "GPS1",
        2: "GPS2",
        3: "GPS3",
        4: "GPS4",
        5: "GPS5",
      },
      typeNeticon: "xinhaoshaixuan",
      isSelect: true,
    };
  },
  watch: {
    value: {
      handler(value) {
        this.curr_val = value;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["user_info"]),
    rcState() {
      let { uavData } = this;
      return uavData ? uavData.rcState : 0;
    },
    parachute() {
      let { uavData } = this;
      return uavData ? uavData.parachute : 3;
    },
    parachuteState() {
      let { uavData } = this;
      return uavData ? uavData.parachuteState : 2;
    },
    gzSignal() {
      let { uavData } = this;
      let gzSignal = {
        gw: 1,
        zw: 1,
      };
      if (uavData && uavData.signal && uavData.signal.sqe45) {
        gzSignal.gw = uavData.signal.sqe45;
      }
      if (uavData && uavData.signal && uavData.signal.sqe14) {
        gzSignal.zw = uavData.signal.sqe14;
      }
      return gzSignal;
    },
    FlightMode() {
      let { uavData, modeList } = this;
      let flightMode = modeList.find((e) => e.data == uavData?.flightMode);
      return flightMode?.label || "离线状态";
    },
    ModeStatus() {
      let { uavData } = this;
      let name = null;
      if (uavData && uavData.link) {
        if (uavData?.link[0].priority == 2) {
          if (uavData?.link[0].using) {
            name = "专网";
          } else {
            name = "公网";
          }
        } else if (uavData?.link[0].priority == 3) {
          if (uavData?.link[0].using) {
            name = "公网";
          } else {
            name = "专网";
          }
        }
      }
      return name || "离线状态";
    },
    priority() {
      let { uavData } = this;
      let name = "离线";
      if (uavData && uavData.priority == 0) {
        name = "自动切换";
      } else if (uavData && uavData.priority == 1) {
        name = "图传";
      } else if (uavData && uavData.priority == 2) {
        name = "专网";
      } else if (uavData && uavData.priority == 3) {
        name = "公网";
      }
      return name;
    },
    fire_state() {
      let { nestData } = this;
      return nestData?.fireWarn ? nestData.fireWarn : 0;
    },

    FIX() {
      let { uavData } = this;
      return (uavData && uavData.isRtkEnable == true) || 0;
    },
    water_state() {
      let { nestData } = this;
      return nestData?.waterWarn ? nestData.waterWarn : 0;
    },
    is_charger() {
      let { nestData } = this;
      return nestData && nestData.chargerPower == 1;
    },
    power_state() {
      let { uavBattery } = this;
      if (uavBattery && uavBattery.chargeRemaining) {
        return parseInt(uavBattery && uavBattery.chargeRemaining / 20 + 1);
      } else {
        return 1;
      }
    },
    remote_state() {
      let { uavData } = this;
      return uavData ? (uavData.isRcConnecting ? 1 : 0) : 0;
    },
    voltage() {
      let { uavBattery } = this;
      return uavBattery ? uavBattery.voltage || 0 : 0;
    },
    power_num() {
      let { uavBattery } = this;
      return uavBattery ? uavBattery.chargeRemaining : 0;
    },
    date() {
      let { now } = this;
      return now ? `${now.format("YYYY/MM/DD")}` : "0000/00/00";
    },
    week() {
      let { now, week_map } = this;
      return now ? `周${week_map[now.day()]}` : "周N/A";
    },
    time() {
      let { now } = this;
      return now ? `${now.format("HH:mm")}` : "00:00";
    },
    satelliteCount() {
      let { uavData } = this;
      return uavData?.gps?.satelliteCount || 0;
    },
    signal_state() {
      let { uavData } = this;
      if (uavData && uavData.satelliteCount) {
        let count = parseInt(uavData.satelliteCount / 6);
        if (count >= 5) {
          count = 5;
        } else {
          count += 1;
        }
        return uavData.satelliteCount ? count : 1;
      }
      return 5;
    },
  },
  methods: {
    // 跳转到首页
    goHome() {
      let { href } = this.$router.resolve({ path: "/home" });
      window.open(href, "_self");
      setTimeout(() => {
        this.$router.go(0);
      }, 100);
    },
  },
  created() { },
};
</script>

<style lang="scss" scoped>
.nest_header_box {
  position: absolute;
  top: 0;
  width: 100%;
  height: 64px;
  background: #0E3042;
  // height: 68px;
  // background: center url("~@/assets/img/head_bg.png");
  background-size: 100%;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin-left: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      margin-top: -10px;

      img {
        width: 63px;
        height: 58px;
        // margin-right: 43px;
        margin-right: 30px;
      }

      span {
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
      }
    }

    .menu {
      margin-left: 150px;

      ul {
        list-style: none;
        display: flex;
        height: 100%;
        vertical-align: top;

        .tap {
          width: 185px;
          height: 64px;
          position: relative;
          text-align: center;
          // margin: -8px 0 0 0;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }
  }

  .right {
    display: flex;
    flex-direction: row;
  }

  .tap {
    background-image: url("~@/assets/img/head-menu-bg-nomal.png");
    background-size: 100% 100%;
    cursor: pointer;
  }

  .tap:hover {
    background-image: url("~@/assets/img/head-menu-bg-select.png");
    background-size: 100% 100%;
  }

  .jin {
    background-image: url("~@/assets/img/head-menu-bg-select.png");
    background-size: 100% 100%;
  }

  .gohome {
    left: 50%;
    transform: translateX(-50%);
  }

  .ZW {
    width: 25px;
    height: 25px;
    background: url("~@/assets/images/observe/fckernel/nest/ZW.svg") no-repeat;
    background-size: 100% 100%;
  }

  .GW {
    width: 25px;
    height: 25px;
    background: center url("~@/assets/images/observe/fckernel/nest/GW.svg") no-repeat;
    background-size: 100% 100%;
  }

  .nest_header_box_right {
    height: 62px;
    min-width: 490px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .changeBtn {
      position: absolute;
      top: 5px;
      right: calc(50% - 370px);
      width: 150px;
      height: 50px;
      background: center url("~@/assets/images/observe/fckernel/nest/right.png");
      background-repeat: no-repeat;
      background-position-y: -8px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #70f6f9;
    }

    .input_group {
      .symbol-icon-box {
        svg {
          width: 24px;
          height: 24px;
        }
      }

      display: flex;
      align-items: center;

      /deep/.el-select {
        width: 80px;
        color: #fff;
        border: none;
        background: transparent;

        .el-input--suffix {
          display: flex;
        }

        .el-input__inner {
          position: relative;
          right: -8px;
          width: 50px;
          padding: 0;
          border: none;
          color: #fff;
          background: transparent;
        }

        .el-input__suffix {
          position: relative;
        }
      }
    }

    .jgjl {
      background: url("~@/assets/images/observe/fckernel/nest/jgjl.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .changeBtn:hover {
      background: center url("~@/assets/images/observe/fckernel/nest/rightD.png");
      background-position-y: -8px;
      background-repeat: no-repeat;
      font-size: 14px;
      color: #ffe9ae;
      letter-spacing: 0;
      font-weight: 700;
    }

    .isSelect {
      background: center url("~@/assets/images/observe/fckernel/nest/btnActiveRight.png");
      background-size: contain;
      background-position-y: -8px;
      background-repeat: no-repeat;
      font-size: 14px;
      color: #ffe9ae;
      letter-spacing: 0;
      font-weight: 700;
    }
  }

  .nest_header_box_left {
    margin-top: -5px;
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;
    padding-left: 117px;

    .changeBtn {
      position: absolute;
      top: 5px;
      left: calc(50% - 370px);
      width: 150px;
      height: 50px;
      background: center url("~@/assets/images/observe/fckernel/nest/leftD.png");
      background-repeat: no-repeat;
      background-position-y: -8px;
      font-size: 14px;
      color: #70f6f9;
      letter-spacing: 0;
    }

    .changeBtn:hover {
      background: center url("~@/assets/images/observe/fckernel/nest/left.png");
      // background-size: contain;
      background-repeat: no-repeat;
      background-position-y: -8px;
      font-size: 14px;
      color: #ffe9ae;
      letter-spacing: 0;
      font-weight: 700;
    }

    .isSelect {
      background: center url("~@/assets/images/observe/fckernel/nest/btnActive.png");
      background-size: contain;
      background-position-y: -8px;
      background-repeat: no-repeat;
      font-size: 14px;
      color: #ffe9ae;
      letter-spacing: 0;
      font-weight: 700;
    }

    .isFlight {
      font-family: YouSheBiaoTiHei;
      font-size: 28px;
      color: #6aff64;
      letter-spacing: 0;
      font-weight: 400;
    }

    .noFlight {
      font-family: YouSheBiaoTiHei;
      font-size: 28px;
      color: #ffc15b;
      letter-spacing: 0;
      font-weight: 400;
    }
  }

  .nav-list {
    width: 590px;
    height: 77px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;

    .nav-item {
      width: 223px;
      height: 77px;
      box-sizing: border-box;
      background: center url("~@/assets/images/observe/fckernel/nav-item.png") no-repeat;
      background-size: cover;
      text-align: center;
      cursor: pointer;
      transition: 0.3s;

      &:hover,
      &.selected {
        background: center url("~@/assets/images/observe/fckernel/nav-item-selected.png") no-repeat;

        .text-box {
          color: #3df1ff;
        }
      }

      .text-box {
        line-height: 77px;
        font-family: Microsoft YaHei Bold;
        font-size: 20px;
        color: #aab8d1;
        font-weight: 700;
        transition: 0.3s;
      }

      &._2 {
        transform: scaleX(-1);

        .text-box {
          transform: scaleX(-1);
        }
      }
    }
  }

  .date-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    box-sizing: border-box;

    .yyyy {
      font-family: Microsoft YaHei;
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 0;
      font-weight: 400;
      margin-bottom: 3px;
    }

    .week-box {
      display: flex;
      justify-content: space-between;

      .week {
        font-family: Microsoft YaHei;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 400;
      }

      .hhmm {
        font-family: Microsoft YaHei;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }
}
</style>
