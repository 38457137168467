<template>
  <div class="page-observe-fckernel-uav">
    <Header :uav-data="uav.control.data"></Header>
    <div class="task-main">
      <!-- 顶部四个方块 -->
      <div class="info-c">
        <div class="item">
          <div class="l">
            <span class="n">待审核</span>
            <span class="v">{{ statusCount.waitApply }}</span>
          </div>
          <div class="r">
            <div class="i"></div>
          </div>
        </div>
        <div class="item">
          <div class="l">
            <span class="n">待配送</span>
            <span class="v">{{ statusCount.waitDelivery }}</span>
          </div>
          <div class="r">
            <div class="i"></div>
          </div>
        </div>
        <div class="item">
          <div class="l">
            <span class="n">已取消</span>
            <span class="v">{{ statusCount.cancelCount }}</span>
          </div>
          <div class="r">
            <div class="i"></div>
          </div>
        </div>
        <div class="item">
          <div class="l">
            <span class="n">已完成</span>
            <span class="v">{{ statusCount.finishedCount }}</span>
          </div>
          <div class="r">
            <div class="i"></div>
          </div>
        </div>
      </div>
      <!-- 查询操作 -->
      <div class="search-c">
        <el-form ref="searchForm" :inline="true" :model="formInline" class="demo-form-inline">
          <div class="v">
            <el-form-item label="关键字" prop="keywords">
              <el-input v-model="formInline.keywords" placeholder="请输入关键字" size="mini" clearable @clear="onSubmitSearch"></el-input>
            </el-form-item>
            <el-form-item label="任务状态" prop="status">
              <el-select v-model="formInline.status" size="mini" clearable @clear="onSubmitSearch">
                <el-option v-for="(item, index) in taskStatus" :label="item.label" :value="item.value" :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="无人机名称" prop="deviceName">
              <el-input v-model="formInline.deviceName" placeholder="请输入无人机名称或SN号" size="normal" style="width: 220px;" clearable @clear="onSubmitSearch"></el-input>
            </el-form-item>
            <el-form-item label="起止时间" prop="rangTime">
              <el-date-picker clearable @clear="onSubmitSearch" v-model="formInline.rangTime" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" size="mini" range-separator="至">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="o-1">
            <el-form-item>
              <el-button class="r-btn" type="info" @click="onResetSearch" size="mini">重置</el-button>
              <el-button class="s-btn" type="primary" @click="onSubmitSearch" size="mini">搜索</el-button>
            </el-form-item>
          </div>
          <div class="o-2">
            <el-form-item>
              <el-button class="d-btn" type="danger" size="mini" @click="handleDelDevByIds">删除</el-button>
              <el-button class="c-btn" type="primary" size="mini" @click="handleCreateNewTask"> 创建 </el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 表格 -->
      <div class="table-c">
        <div class="r-table">
          <el-table :data="tableData" style="width: 100%" fit :row-class-name="tableRowClassName" @selection-change="handleMultipleTableSelectionChange">
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column prop="taskId" label="序号" width="80" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="deliveryId" label="订单编号" width="140" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.deliveryId || '' }}
              </template>
            </el-table-column>
            <el-table-column prop="taskStatus" label="任务状态" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <!-- <div v-if="scope.row.taskStatus == -2" class="taskStatus taskStatus-2">待审核</div>
                <div v-else-if="scope.row.taskStatus == -1" class="taskStatus taskStatus-1">待派发</div>
                <div v-else-if="scope.row.taskStatus == 0" class="taskStatus taskStatus-0">待执行</div>
                <div v-else-if="scope.row.taskStatus == 3" class="taskStatus taskStatus-3">执行中</div>
                <div v-else-if="scope.row.taskStatus == 1" class="taskStatus taskStatus-11">执行完成</div>
                <div v-else-if="scope.row.taskStatus == 5" class="taskStatus taskStatus-5">待收货</div>
                <div v-else class="taskStatus taskStatus-99">已取消</div> -->
                <div v-if="scope.row.taskStatus == -2" style="color: #FF9A0E; background: rgba(255,184,0,0.13); width: 50%; margin: 0 auto;">待审核
                </div>
                <div v-else-if="scope.row.taskStatus == -1" style="color: #FFDE6B; background: rgba(255,214,0,0.13); width: 50%; margin: 0 auto;">
                  待放件
                </div>
                <div v-else-if="scope.row.taskStatus == 0" style="color: #19E210; background: rgba(0,255,163,0.13); width: 50%; margin: 0 auto;">待配送
                </div>
                <div v-else-if="scope.row.taskStatus == 3" style="color: #00FFC2; background: rgba(0,255,194,0.13); width: 50%; margin: 0 auto;">配送中
                </div>
                <div v-else-if="scope.row.taskStatus == 1" style="color: #4ECAFF; background: rgba(0,194,255,0.13); width: 50%; margin: 0 auto;">已完成
                </div>
                <div v-else-if="scope.row.taskStatus == 5" style="color: #E6FF4A; background: rgba(230,255,74,0.13); width: 50%; margin: 0 auto;">
                  待收货
                </div>
                <div v-else style="color: rgba(255,255,255,0.76); background: rgba(255,254,249,0.12); width: 50%; margin: 0 auto;">
                  已取消
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="deviceName" label="无人机名称" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="takeoffApronName" label="起飞点" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="landApronName" label="降落点" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="fromCompany" label="任务来源" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="name" label="所属区域" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.cityName }} {{ scope.row.countyName }}
              </template>
            </el-table-column>
            <el-table-column prop="deliveryTime" label="创建时间" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="finishTime" label="结束时间" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.finishTime || '/' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="操作" width="180" align="center">
              <template slot-scope="scope">
                <el-button v-if="scope.row.taskStatus == 0 || scope.row.taskStatus == 3" size="mini" type="text" @click="handleEcexuteFly(scope.$index, scope.row)">
                  <span class="op-name">执飞</span>
                </el-button>
                <el-button v-if="scope.row.taskStatus == -2" size="mini" type="text" @click="handleTaskReview(scope.$index, scope.row)">
                  <span class="op-name">审核</span>
                </el-button>
                <el-button size="mini" type="text" @click="handleDetail(scope.$index, scope.row)">
                  <span class="op-name">详情</span>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="p">
          <el-pagination background layout="prev, pager, next, sizes, jumper" :total="mainTableTotal" @current-change="handleCurrentChange" @size-change="handleSizeChange">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 侧边栏 0 无人机， 1 任务订单，2 视频墙 -->
    <SideBar :selectedFlag="1" class="side-bar"></SideBar>

    <!-- 创建任务抽屉 -->
    <el-drawer title="" :visible.sync="drawerTaskCreate" :direction="drawDirection" size="700px" :with-header="false" custom-class="drawer-panel drawer-task ">
      <!-- 顶部，新建/编辑任务 -->
      <div class="header">
        <div class="l">
          <img src="@/assets/img/i159.png" alt="" style="width:21px; height:21px" />
          <span style="width:100px; margin-left: 0; ">新建配送订单</span>
        </div>
        <div class="r">
          <img @click="drawerTaskCreate = false" src="@/assets/img/i151.png" alt="" />
        </div>
      </div>
      <!-- 新建配送订单body表单 -->
      <div class="create-task-body">
        <div class="create-task">
          <el-form ref="form" :model="formData" :rules="createTaskRules" label-position="left">
            <!-- 三个卡片(运单基本信息、发货信息、收件人)
            <! 运单基本信息卡片 -->
            <el-card class="card" header="运单基本信息" style="border: 1px solid rgba(163, 177, 187, 0.8); font-family: PingFang SC, PingFang SC; font-weight: 500; font-size: 15px; border-bottom: 1px solid rgba(163, 177, 187, 0.8);">
              <el-row :gutter="20">
                <el-col :span="10">
                  <el-form-item label="来源" prop="fromCompany">
                    <el-select v-model="formData.fromCompany" placeholder="请选择订单来源" @change="companyChange">
                      <el-option v-for="item in companyList" :key="item.code" :label="item.text" :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="13">
                  <el-form-item label="运单号" prop="deliveryId">
                    <el-row :gutter="5">
                      <el-col :span="17">
                        <el-input :disabled="deliveryIdChecked" v-model="formData.deliveryId" :placeholder="deliveryIdChecked ? '运单号将会自动生成' : '请输入运单号'"></el-input>
                      </el-col>
                      <el-col :span="7">
                        <el-button :type="deliveryIdChecked ? 'primary' : 'warning'" @click="deliveryIdChange" :style="deliveryIdChecked ? 'background-color: #007aff;border-color:#007aff': ' background-color: #e64340;border-color:#e64340'">
                          {{ deliveryIdChecked ? '手动录入' : '自动生成' }}
                        </el-button>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="17">
                  <el-form-item label="虚拟号" prop="unrealTel">
                    <el-input v-model="formData.unrealTel" maxlength="11" placeholder="请输入虚拟手机号"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item prop="unrealTelExt">
                    <el-input v-model="formData.unrealTelExt" maxlength="6" placeholder="分机号"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="真实号" prop="realTel">
                <el-input v-model="formData.realTel" maxlength="11" placeholder="请输入真实手机号" @input="realTelChange"></el-input>
              </el-form-item>
              <el-form-item label="城市" prop="cityName">
                <el-select v-model="formData.cityName" placeholder="请选择城市" @change="cityChange">
                  <el-option v-for="(item, index) in cityList" :key="index" :label="item.text" :value="item.value" />
                </el-select>
              </el-form-item>
              <el-form-item label="起始点" prop="takeoffApronId">
                <el-select v-model="formData.takeoffApronId" placeholder="请选择无人机起飞点" @change="takeoffApronChange">
                  <el-option v-for="(item, index) in landList" :key="index" :label="item.text" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="终止点" prop="landApronId">
                <el-select v-model="formData.landApronId" placeholder="请选择无人机降落点" @change="landApronChange">
                  <el-option v-for="(item, index) in landList" :key="index" :label="item.text" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="区县" prop="countyName" style="margin-bottom: 8px;">
                <el-input v-model="formData.countyName" placeholder="请输入区县名称"></el-input>
              </el-form-item>
            </el-card>

            <!-- 发货信息卡片 -->
            <el-card class="card" header="发货信息" style="border: 1px solid rgba(163, 177, 187, 0.8);">
              <el-form-item label="地址" prop="fromAddress">
                <el-input v-model="formData.fromAddress" placeholder="北京市海定区"></el-input>
              </el-form-item>
              <el-form-item label="详细地址" prop="fromUserNote" style="margin-bottom: 8px;">
                <el-input type="textarea" v-model="formData.fromUserNote" placeholder="幸福大街X号楼X单元XXX室"></el-input>
              </el-form-item>
            </el-card>

            <!-- 收件人卡片 -->
            <el-card class="card" header="收件人" style="border: 1px solid rgba(163, 177, 187, 0.8);">
              <el-form-item label="姓名" prop="receiver">
                <el-input v-model="formData.receiver" placeholder="请填写收件人名称"></el-input>
              </el-form-item>
              <el-form-item label="地址" prop="toAddress">
                <el-input v-model="formData.toAddress" placeholder="北京市海定区"></el-input>
              </el-form-item>
              <el-form-item label="详细地址" prop="toUserNote">
                <el-input type="textarea" v-model="formData.toUserNote" placeholder="望京街道X号楼X单元XXX室"></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="note" style="margin-bottom: 8px;">
                <el-input type="textarea" v-model="formData.note"></el-input>
              </el-form-item>
            </el-card>
          </el-form>
          <div class="bottom-view" style="display: flex; justify-content: flex-end; margin:20px 23px 20px 0">
            <el-button type="primary" @click="checkData" style="width:110px; background-color: #007aff; border-color:#007aff">创建订单</el-button>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 任务审核抽屉 -->
    <el-drawer title="" :visible.sync="drawerTaskReview" :direction="drawDirection" size="65%" :with-header="false" custom-class="drawer-panel drawer-task">
      <div class="header">
        <div class="l">
          <img src="@/assets/img/i150.png" alt="">
          <span>任务审核</span>
        </div>
        <div class="r">
          <img @click="drawerTaskReview = false" src="@/assets/img/i151.png" alt="">
        </div>
      </div>
      <div class="body">
        <div class="order-info">
          <div class="h">
            <img src="@/assets/img/i152.png" alt="">
            <span>订单信息</span>
          </div>
          <div class="b">
            <div class="ll">
              <el-row class="row">
                <el-col :span="8">
                  <el-row>
                    <el-col :span="6" class="label">
                      订单编号:
                    </el-col>
                    <el-col :span="18" class="text-v">
                      {{ orderDetails.deliveryId || '' }}
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <el-row>
                    <el-col :span="6" class="label">
                      订单类别:
                    </el-col>
                    <el-col :span="18" class="text-v">
                      {{ orderDetails.fromCompany || '' }}
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <el-row>
                    <el-col :span="8" class="label">
                      订单发起时间:
                    </el-col>
                    <el-col :span="16" class="text-v">
                      {{ orderDetails.deliveryTime }}
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row class="row">
                <el-col :span="8">
                  <el-row>
                    <el-col :span="6" class="label">
                      订单状态：
                    </el-col>
                    <el-col :span="18" class="text-v">
                      {{ orderDetails.statusMsg }}
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <el-row>
                    <el-col :span="7" class="label">
                      订单所在地：
                    </el-col>
                    <el-col :span="17" class="text-v">
                      <!-- {{ orderDetails.countyName }}-{{ orderDetails.cityName }} -->
                      <span>{{ orderDetails.countyName }}</span>
                      <span v-if="orderDetails.countyName">-</span>
                      <span>{{ orderDetails.cityName }}</span>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <el-row>
                    <el-col :span="8" class="label">
                      联系方式：
                    </el-col>
                    <el-col :span="16" class="text-v">
                      <!-- {{ orderDetails.toUserNote }} -->
                      无
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row class="row">
                <el-col :span="8">
                  <el-row>
                    <el-col :span="6" class="label">
                      订单来源：
                    </el-col>
                    <el-col :span="18" class="text-v">
                      {{ orderDetails.fromCompany }}
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <el-row>
                    <el-col :span="6" class="label">
                      收件人：
                    </el-col>
                    <el-col :span="18" class="text-v">
                      {{ orderDetails.receiver }}
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <el-row>
                    <el-col :span="8" class="label">
                      任务区域：
                    </el-col>
                    <el-col :span="16" class="text-v">
                      <el-link @click="initViewer1(1)" style="color: #3AD4E0;">查看</el-link>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row class="row">
                <el-col :span="8">
                  <el-row>
                    <el-col :span="6" class="label">
                      发货地址：
                    </el-col>
                    <el-col :span="18" class="text-v">
                      {{ orderDetails.fromAddress }}
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <el-row>
                    <el-col :span="6" class="label">
                      收货地址：
                    </el-col>
                    <el-col :span="18" class="text-v">
                      {{ orderDetails.toAddress }}
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <el-row>
                    <el-col :span="6" class="label">

                    </el-col>
                    <el-col :span="18" class="text-v">

                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row class="row row-remark">
                <el-col :span="24">
                  <el-row>
                    <el-col :span="2" class="label">
                      备注：
                    </el-col>
                    <el-col :span="21" class="text-v">
                      <el-input type="textarea" :rows="2" placeholder="" v-model="textarea" disabled>
                      </el-input>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
            <div class="m">
              <!-- <OLMap></OLMap> -->
              <div id="orderInfoMapId"></div>
            </div>
          </div>
        </div>
        <div class="task-schedu">
          <div class="h">
            <img src="@/assets/img/i152.png" alt="">
            <span>任务调度</span>
          </div>
          <div class="b">
            <div class="ll">
              <el-form :model="taskReviewScheduForm" ref="taskReviewScheduForm" label-width="80px" class="demo-ruleForm" label-position="left">
                <el-form-item label="起始点">
                  <el-col :span="10">
                    <el-form-item>
                      <el-select v-model="taskReviewScheduForm.takeoffApronId" placeholder="请选择起始点" size="small" @change="handleTakeoffAndLandApronIdChange">
                        <el-option v-for="(item, index) in landListOption" :key="index" :label="item.name" @click.native="handleTakeoffApron(item)" :value="item.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col class="line" :span="2">
                    <img src="@/assets/img/i154.png" alt="">
                  </el-col>
                  <el-col class="line" :span="2">终止点</el-col>
                  <el-col :span="10">
                    <el-form-item>
                      <el-select v-model="taskReviewScheduForm.landApronId" placeholder="请选择终止点" size="small" @change="handleTakeoffAndLandApronIdChange">
                        <el-option v-for="(item, index) in landListOption" :key="index" :label="item.name" @click.native="handlelandApron(item)" :value="item.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-form-item>
                <el-form-item label="任务航线" class="item-content">
                  <el-col :span="10">
                    <el-form-item prop="flightLineId">
                      <el-select v-model="taskReviewScheduForm.flightLineId" placeholder="请选择航线名称" size="small" clearable @visible-change="handleTaskAirLineChange">
                        <el-option v-for="(item, index) in airLineListOption" :key="index" :label="item.title" @click.native="handleFlightLine(item)" :value="item.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col class="line" :span="3">
                    <el-button class="btn-p" size="mini" @click="previewAirLine">预览</el-button>
                  </el-col>
                  <el-col class="line" :span="2">
                    <el-button class="btn-d" size="mini" v-if="false">航线绘制</el-button>
                  </el-col>
                </el-form-item>
                <el-form-item label="无人机" class="has-icon" props="deviceHardId">
                  <el-col :span="10">
                    <el-select v-model="taskReviewScheduForm.deviceHardId" placeholder="请选择无人机" size="small">
                      <!-- <template slot="prefix">
                        <img src="@/assets/img/i155.png" style="width: 47px; height: 17px; margin-top: 13px;" alt="">
                      </template> -->
                      <el-option v-for="(item, index) in deviceListForLogisticsListOption" :label="item.deviceName" :key="index" :value="item.deviceHardId"></el-option>
                    </el-select>
                  </el-col>
                  <el-col class="line" :span="2">
                    <el-button class="btn-v" size="mini" v-if="false">查看分布</el-button>
                  </el-col>
                </el-form-item>
                <el-form-item label="驾驶员" prop="flyIds">
                  <el-col :span="10">
                    <el-select v-model="taskReviewScheduForm.flyIds" placeholder="请选择驾驶员" size="small" multiple>
                      <!-- <template slot="prefix">
                        <img src="@/assets/img/i155.png" style="width: 47px; height: 17px; margin-top: 13px;" alt="">
                      </template> -->
                      <el-option v-for="(item, index) in flyerList" :key="index" :label="item.title" :value="item.id"></el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
              </el-form>
            </div>
            <div class="m">
              <div id="taskScheduMapId"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="foot">
        <el-button class="c" @click="handleCancel">取消订单</el-button>
        <el-button class="p" @click="handleApprove">审核通过</el-button>
      </div>
    </el-drawer>
    <!-- 任务详情抽屉 -->
    <el-drawer title="" :visible.sync="drawerTaskDetail" :direction="drawDirection" size="65%" :with-header="false" custom-class="drawer-panel drawer-task">
      <div class="header">
        <div class="l">
          <img src="@/assets/img/i150.png" alt="">
          <span>任务详情</span>
        </div>
        <div class="r">
          <img @click="drawerTaskDetail = false" src="@/assets/img/i151.png" alt="">
        </div>
      </div>
      <div class="body">
        <div class="order-info">
          <div class="h">
            <img src="@/assets/img/i152.png" alt="">
            <span>订单信息</span>
          </div>
          <div class="b">
            <div class="ll">
              <el-row class="row">
                <el-col :span="8">
                  <el-row>
                    <el-col :span="6" class="label">
                      订单编号:
                    </el-col>
                    <el-col :span="18" class="text-v">
                      {{ orderDetails.deliveryId || '' }}
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <el-row>
                    <el-col :span="6" class="label">
                      订单类别:
                    </el-col>
                    <el-col :span="18" class="text-v">
                      {{ orderDetails.fromCompany || '' }}
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <el-row>
                    <el-col :span="8" class="label">
                      订单发起时间:
                    </el-col>
                    <el-col :span="16" class="text-v">
                      {{ orderDetails.deliveryTime }}
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row class="row">
                <el-col :span="8">
                  <el-row>
                    <el-col :span="6" class="label">
                      订单状态：
                    </el-col>
                    <el-col :span="18" class="text-v">
                      {{ orderDetails.statusMsg }}
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <el-row>
                    <el-col :span="7" class="label">
                      订单所在地：
                    </el-col>
                    <el-col :span="17" class="text-v">
                      <span>{{ orderDetails.countyName }}</span>
                      <span v-if="orderDetails.countyName">-</span>
                      <span>{{ orderDetails.cityName }}</span>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <el-row>
                    <el-col :span="8" class="label">
                      联系方式：
                    </el-col>
                    <el-col :span="16" class="text-v">
                      <!-- {{ orderDetails.fromCompany }} -->
                      无
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row class="row">
                <el-col :span="8">
                  <el-row>
                    <el-col :span="6" class="label">
                      订单来源：
                    </el-col>
                    <el-col :span="18" class="text-v">
                      {{ orderDetails.fromCompany }}
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <el-row>
                    <el-col :span="6" class="label">
                      收件人：
                    </el-col>
                    <el-col :span="18" class="text-v">
                      {{ orderDetails.receiver }}
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <el-row>
                    <el-col :span="8" class="label">
                      任务区域：
                    </el-col>
                    <el-col :span="16" class="text-v">
                      <el-link @click="initViewer3(1)" style="color: #3AD4E0;">查看</el-link>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row class="row">
                <el-col :span="8">
                  <el-row>
                    <el-col :span="6" class="label">
                      发货地址：
                    </el-col>
                    <el-col :span="18" class="text-v">
                      {{ orderDetails.fromAddress }}
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <el-row>
                    <el-col :span="6" class="label">
                      收货地址：
                    </el-col>
                    <el-col :span="18" class="text-v">
                      {{ orderDetails.toAddress }}
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <el-row>
                    <el-col :span="6" class="label">

                    </el-col>
                    <el-col :span="18" class="text-v">

                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row class="row row-remark">
                <el-col :span="24">
                  <el-row>
                    <el-col :span="2" class="label">
                      备注：
                    </el-col>
                    <el-col :span="21" class="text-v">
                      <el-input type="textarea" :rows="2" placeholder="" v-model="orderDetails.note" disabled>
                      </el-input>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
            <div class="m">
              <div id="detailOrderInfoMapId"></div>
            </div>
          </div>
        </div>
        <div class="task-schedu detail-task-schedu">
          <div class="h">
            <img src="@/assets/img/i152.png" alt="">
            <span>任务调度</span>
            <div class="f">
              <!-- <span>{{orderDetails.statusMsg}}</span> -->

              <!-- <div v-if="orderDetails.statusMsg == -2" class="taskStatus taskStatus-2">待审核</div>
              <div v-else-if="orderDetails.statusMsg == -1" class="taskStatus taskStatus-1">待派发</div>
              <div v-else-if="orderDetails.statusMsg == 0" class="taskStatus taskStatus-0">待执行</div>
              <div v-else-if="orderDetails.statusMsg == 3" class="taskStatus taskStatus-3">执行中</div>
              <div v-else-if="orderDetails.statusMsg == 1" class="taskStatus taskStatus-11">执行完成</div>
              <div v-else-if="orderDetails.statusMsg == 5" class="taskStatus taskStatus-5">待收货</div>
              <div v-else class="taskStatus taskStatus-99">已取消</div> -->

              <div v-if="orderDetails.status == -2" style="color: #FF9A0E; background: rgba(255,184,0,0.13); width: 64px; height: 28px;" class="taskStatus-label">
                待审核</div>
              <div v-else-if="orderDetails.status == -1" style="color: #FFDE6B; background: rgba(255,214,0,0.13); width: 64px; height: 28px;" class="taskStatus-label">待放件
              </div>
              <div v-else-if="orderDetails.status == 0" style="color: #19E210; background: rgba(0,255,163,0.13); width: 64px; height: 28px;" class="taskStatus-label">待配送
              </div>
              <div v-else-if="orderDetails.status == 3" style="color: #00FFC2; background: rgba(0,255,194,0.13); width: 64px; height: 28px;" class="taskStatus-label">配送中
              </div>
              <div v-else-if="orderDetails.status == 1" style="color: #4ECAFF; background: rgba(0,194,255,0.13); width: 64px; height: 28px;" class="taskStatus-label">已完成
              </div>
              <div v-else-if="orderDetails.status == 5" style="color: #E6FF4A; background: rgba(230,255,74,0.13); width: 64px; height: 28px;" class="taskStatus-label">待收货
              </div>
              <div v-else style="color: rgba(255,255,255,0.76); background: rgba(255,254,249,0.12); width: 64px; height: 28px;" class="taskStatus-label">
                已取消
              </div>

            </div>
          </div>
          <div class="b">
            <div class="ll">
              <el-form :model="ruleForm" ref="taskScheduForm" label-width="80px" class="demo-ruleForm" label-position="left">
                <el-form-item label="起始点">
                  <el-col :span="15">
                    <span class="info-text">{{ orderDetails.takeoffApronName }}</span>
                    <!-- <el-form-item prop="date1">
                      <el-select v-model="ruleForm.region" placeholder="请选择起始点" size="small">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                      </el-select>
                    </el-form-item> -->
                  </el-col>
                </el-form-item>
                <div class="line-1">
                  <img src="@/assets/img/i156.png" alt="">
                </div>
                <el-form-item label="终止点">
                  <el-col :span="15">
                    <span class="info-text">{{ orderDetails.landApronName }}</span>
                    <!-- <el-form-item prop="date1">
                      <el-select v-model="ruleForm.region" placeholder="请选择起始点" size="small">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                      </el-select>
                    </el-form-item> -->
                  </el-col>
                </el-form-item>
                <el-form-item label="任务航线" prop="delivery" class="item-content">
                  <el-col :span="15">
                    <span class="info-text">{{ orderDetails.flightLineName }}</span>
                    <!-- <el-form-item prop="date1">
                      <el-select v-model="ruleForm.region" placeholder="请选择航线名称" size="small">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                      </el-select>
                    </el-form-item> -->
                  </el-col>
                  <el-col class="line" :span="3">
                    <!-- <el-button class="btn-p" size="mini">预览</el-button> -->
                  </el-col>
                </el-form-item>
                <el-form-item label="无人机" prop="type" class="has-icon">
                  <el-col :span="15">
                    <span class="info-text">{{ orderDetails.deviceName }}</span>
                    <!-- <el-select v-model="ruleForm.region" placeholder="" size="small">
                      <template slot="prefix">
                        <img src="@/assets/img/i155.png" style="width: 47px; height: 17px; margin-top: 13px;" alt="">
                      </template>
                      <el-option label="区域一" value="shanghai"></el-option>
                      <el-option label="区域二" value="beijing"></el-option>
                    </el-select> -->
                  </el-col>
                </el-form-item>
                <el-form-item label="驾驶员" prop="resource">
                  <el-col :span="15">
                    <span class="info-text">{{ [...orderDetails.flyIdNames].join(', ') }}</span>
                    <!-- <el-select v-model="ruleForm.region" placeholder="" size="small">
                      <template slot="prefix">
                        <img src="@/assets/img/i155.png" style="width: 47px; height: 17px; margin-top: 13px;" alt="">
                      </template>
                      <el-option label="区域一" value="shanghai"></el-option>
                      <el-option label="区域二" value="beijing"></el-option>
                    </el-select> -->
                  </el-col>
                </el-form-item>
              </el-form>
            </div>
            <div class="m">
              <div class="o-l-g">
                <div class="h">操作记录</div>
                <div class="c-h">
                  <el-row class="r h-r">
                    <el-col :span="3" class="c h-c">
                      <div class="t h-t">编号</div>
                    </el-col>
                    <el-col :span="12" class="c h-c">
                      <div class="t h-t">操作描述</div>
                    </el-col>
                    <el-col :span="9" class="c h-c">
                      <div class="t h-t">时间</div>
                    </el-col>
                  </el-row>
                </div>
                <!--  -->
                <div class="c-b">
                  <el-row class="r h-r" v-for="(item, index) in orderDetails.deliveryFlow" :key="index" :class="[index % 2 != 0 ? 'r-odd' : 'r-ever']">
                    <el-col :span="3" class="c h-c">
                      <div class="t h-t">{{ index + 1 }}</div>
                    </el-col>
                    <el-col :span="12" class="c h-c">
                      <div class="t h-t">{{ item.details }}</div>
                    </el-col>
                    <el-col :span="9" class="c h-c">
                      <div class="t h-t">{{ item.createTime }}</div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="foot">
        <!-- <el-button class="c">取消订单</el-button> -->
        <el-button class="p" @click="drawerTaskDetail = false">确定</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UavList from "./components/list";
import FloatPlayer from "./components/float-playerfk";
import data from "./data";
import methods from "./methods";
import Header from "./components/header/index";
import TakeOver from "./components/takeOver";
import pitchyaw from "./components/pitchyaw";
import API from "@/api";
import { getTaskOrderNum } from '@/utils/common';
import Item from "./item";
// 侧边栏
import SideBar from '../home/components/sideBar';
// 航线库面板
import AirLineLibrary from '../fckernel/uav/components/taskList/components/airLineLibrary.vue';
import { Utils } from "@/lib/cesium";
import fk from "./fk.json";
import dayjs from "dayjs";
// 订单信息地图
let viewer1 = null;
// 任务调度地图
let viewer2 = null;
// 任务详情-订单信息地图
let viewer3 = null;
let entityLine = null;
let entityPoint1 = null;
let entityPoint2 = null;
// 任务审核里面，任务调度航线预览
let entityArray = [];
// 定时刷新时间标志
let refreshTime = 0;
export default {
  components: { Header, SideBar },
  inject: ["g_cesium_layer"],
  provide() {
    return {
      uav_This: this,
      findList: (search) => {
        if (search) {
          this.uav_list(search);
        } else {
          this.uav_list(search);
          // this.uav_mountList(data.name);
        }
      },
      change_page: () => { },
      fn: (...args) => this.uav_fn(...args),
    };
  },
  data() {
    return {
      ...data(),
      loading: true,
      isFullscreenBool: false, //是否是全屏
      formInline: {
        keywords: '',
        status: '',
        deviceName: '',
        beginTime: '',
        endTime: '',
        rangTime: '',
        pageNo: 1,
        pageSize: 10,
      },
      mainTableTotal: 0,
      taskStatus: [
        { value: '全部', label: '全部' },
        { value: '-2', label: '待审核' },
        { value: '-1', label: '待放件' },
        { value: '0', label: '待配送' },
        { value: '3', label: '配送中' },
        { value: '5', label: '待收货' },
        { value: '1', label: '已完成' },
        { value: '99', label: '已取消' },
      ],
      tableData: [],
      statusCount: {},
      drawerTaskReview: false,
      drawerTaskDetail: false,
      drawerTaskCreate: false,
      drawDirection: 'rtl',
      textarea: '',
      // 任务审核表单
      taskReviewScheduForm: {
        startLandingFieldId: '',
        endLandingFieldId: '',
        deliveryld: '',
        takeoffApronId: '',//起飞点起降场ID
        landApronId: '',
        flightLineId: null,//航线id
        deviceHardId: '',//无人机hardld
        deviceId: null,//无人机id
        flylds: [],//飞手id (驾驶员)
        applyStatus: null,//审批结果，1审批通过2审批未通过
      },
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        region: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ],
        date1: [
          { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
        ],
        date2: [
          { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
        ],
        type: [
          { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
        ],
        resource: [
          { required: true, message: '请选择活动资源', trigger: 'change' }
        ],
        desc: [
          { required: true, message: '请填写活动形式', trigger: 'blur' }
        ]
      },
      orderInfo: {},
      landListOption: [],
      airLineListOption: [],
      deviceListForLogisticsListOption: [],
      orderDetails: {},
      flyerList: [],
      //任务调度起始点选择内容
      takeoffApronSelectedData: null,
      //任务调度终止点选择内容
      landApronIdSelectedData: null,
      //任务调度任务航线选择内容
      flightLineSelectedData: null,
      multipleSelection: [], // 表格多选项

      // 新建任务提交表单弹出框内容
      // ---------------------------------------------------------------------
      // 新建任务提交表单弹出框内容
      deliveryIdChecked: true,
      landList: [],
      companyList: [
        {
          code: 'UU-',
          value: 'UU跑腿',
          text: 'UU跑腿',
          cityList: [
            { value: '郑州市', text: '郑州市' }
          ],
        },
        {
          code: 'LFKS-',
          value: '联飞快送',
          text: '联飞快送',
          cityList: [
            { value: '芜湖市', text: '芜湖市' },
            { value: '深圳市', text: '深圳市' },
            { value: '十堰市', text: '十堰市' },
            { value: '东台市', text: '东台市' },
          ],
        },
        {
          code: 'GLKS-',
          value: '国联快送',
          text: '国联快送',
          cityList: [
            { value: '东台市', text: '东台市' }
          ],
        },
      ],
      formData: {
        fromCompany: '联飞快送',
        cityName: '',
        takeoffApronId: '',
        landApronId: '',
      },
      createTaskRules: {
        // deliveryId: [{ required: true, message: '运单号不能为空', trigger: 'blur' }],
        cityName: [{ required: true, message: '区域城市不能为空', trigger: 'blur' }],
        fromCompany: [{ required: true, message: '订单来源不能为空', trigger: 'blur' }],
        fromAddress: [{ required: true, message: '发货地址不能为空', trigger: 'blur' }],
        landApronId: [{ required: true, message: '起飞点不能为空', trigger: 'blur' }],
        takeoffApronId: [{ required: true, message: '降落点不能为空', trigger: 'blur' }],
        receiver: [{ required: true, message: '收件人姓名不能为空', trigger: 'blur' }],
        toAddress: [{ required: true, message: '收件人地址不能为空', trigger: 'blur' }],
        unrealTelExt: [{ pattern: /^\d+$/, message: '只能为数字', trigger: 'blur' }],
        unrealTel: [{ pattern: /^(13[0-9]|14[5|7|9]|15[0-3,5-9]|166|17[0-8]|18[0-9]|19[8-9])\d{8}$/, message: '请输入正确手机号', trigger: 'blur' }],
        realTel: [{ pattern: /^(13[0-9]|14[5|7|9]|15[0-3,5-9]|166|17[0-8]|18[0-9]|19[8-9])(\d{4}|\*{4})\d{4}$/, message: '请输入正确手机号', trigger: 'blur' }],
      },
    }
  },
  watch: {
    deep: true,
    drawerTaskReview(newVal, oldVal) {
      // console.log('newVal', newVal);
      if (!newVal) {
        // console.log('关闭了');
        this.$refs['taskReviewScheduForm'].resetFields();
      }
    }
  },
  computed: {
    ...mapGetters(["user_info"]),
    uav_mounts() {
      let { mounts } = this.uav.control;
      return mounts
        .map((item) => {
          let find_item = this.mount.list.find(
            (m) => m.name === item.gimbalName
          );
          return find_item || undefined;
        })
        .filter((item) => item);
    },

    // 计算cityList变化
    cityList() {
      if (!this.companyList) return [];
      return this.companyList.find((item) => item.value === this.formData.fromCompany).cityList;
    },
  },
  async mounted() {
    document.title = "任务订单";
    this.getStatusCount();
    // this.taskListPage();
    this.web_landList();
    this.getDeviceListForLogistics();
    this.getFlyerList();
    this.refreshTable();
  },
  beforeDestroy() {
  },
  methods: {
    ...methods,
    // 点击面板顶部标题
    clickTitle(flag) {
      this.titleSelectedFlag = flag;
    },
    change_active_item(index) {
      this.uav.active_item = index;
    },

    splitScreenFn(item) {
      if (item.id != 1) {
        this.loading = true;
      }
      this.uav.itemkey = item.id;
      this.uav.active_item = 0;
      if (item.id == 1) {
        this.uav.videos = [
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
        ];
      } else if (item.id == 4) {
        this.uav.videos = [
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
        ];
      } else if (item.id == 9) {
        this.uav.videos = [
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
        ];
      } else {
        this.uav.videos = [
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
        ];
      }

      setTimeout(() => {
        this.get_uav_videoes();
        this.$forceUpdate();
      }, 1000);
    },
    //监听屏幕是否全屏
    fullScreenChangeFn(e) {
      if (window.screen.height - window.document.body.offsetHeight > 5) {
        this.isFullscreenBool = false;
      } else {
        this.isFullscreenBool = true;
      }
    },
    cesium_layer_change_mode(fn, modes) {
      // 定位到芜湖
      Utils.initLocation2WUHU();
      return;
    },
    /**@Description :  ******************************************* 查询数据
     **@Date: 2024-03-21 15:55:17
    */
    onSubmitSearch() {
      console.log('submit!');
      this.taskListPage();
    },
    onResetSearch() {
      this.$refs['searchForm'].resetFields();
      this.taskListPage();
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 != 0) {
        return 'oddRow';
      }
      return 'evenRow';
    },
    /**@Description :  ******************************************* 统计
     **@Date: 2024-03-18 14:03:10
    */
    async getStatusCount() {
      let res = await API.TASKORDER.statusCount();
      // console.log('统计', res);
      // if (res.status == 1) {
      //   this.statusCount = res.data;
      // }
      this.statusCount = res;
      // console.log('this.statusCount', this.statusCount);
    },
    /**@Description :  ******************************************* 创建新建任务抽屉并提交信息
     **@Date: 2024-07-11 17:00:00
    */
    //  点击创建弹出抽屉
    handleCreateNewTask() {
      this.drawerTaskCreate = true
    },
    companyChange() {
      this.formData.cityName = '';
      this.formData.takeoffApronId = '';
      this.formData.landApronId = '';
    },
    cityChange() {
      this.formData.takeoffApronId = '';
      this.formData.landApronId = '';
    },
    deliveryIdChange() {
      this.deliveryIdChecked = !this.deliveryIdChecked;
    },
    landApronChange(e) {
      this.formData.toAddress = this.landList.find((item) => item.value === e).text;
    },
    takeoffApronChange(e) {
      this.formData.fromAddress = this.landList.find((item) => item.value === e).text;
    },
    realTelChange(value) {
      this.formData.receiver = value;
    },
    checkData() {
      // 先获取表单数据
      const form = this.$refs.form;
      form.validate(async (valid) => {
        if (!valid) {
          console.log('验证不通过');
          return;
        }
        // 表单验证通过，获取实际表单数据
        if (this.deliveryIdChecked) {
          const company = this.companyList.find(item => item.value === this.formData.fromCompany);
          if (company) {
            const pre = company.code;
            this.formData.deliveryId = getTaskOrderNum(pre); // 生成并设置 deliveryId
          }
        }
        try {
          await this.submitData(); // 提交表单数据
        } catch (err) {
          console.log('提交数据时发生错误：', err);
        }
      });
    },

    // 提交任务订单，api 在这里发送
    async submitData() {
      console.log(this.formData);
      try {
        console.log('提交的数据：', this.formData); // 输出提交数据，确认数据格式
        const res = await API.TASKORDER.createOrder(this.formData);
        console.log('API 响应：', res); // 输出 API 响应，确认响应内容
        if (res.status == 0) {
          this.$message({
            message: '运单号不能为空',
            type: 'error',
          });
          return
        }
        if (this.formData.deliveryId == res.deliveryId) {
          this.$message({
            message: '成功创建配送订单',
            type: 'success',
          });
          this.$refs['form'].resetFields()
          // 关闭抽屉
          this.drawerTaskCreate = false;
          // 刷新页面表格
          this.taskListPage();
        } else {
          this.$message.error(res.message);
        }
      } catch (err) {
        this.$message.error(err.message);
      }
    },


    // ***************-----------------------------------------------------------------------新建任务区域



    /**@Description :  ******************************************* 弹出任务审核抽屉
     **@Date: 2024-03-15 18:01:22
    */
    async handleTaskReview(index, row) {
      console.log('index', index);
      console.log('row', row);
      this.drawerTaskReview = true;
      this.orderInfo = row;
      let data = {};
      data.deliveryId = row.deliveryId;
      console.log('data', data);
      let res = await API.TASKORDER.getOrderDetails(data);
      console.log('任务订单详情', res);
      this.orderDetails = res;
      this.$nextTick(() => {
        //判断详情里面的起始点和终止点是否在起降点列表中 
        //起始点的id
        let t1 = _.find(this.landListOption, ['id', this.orderDetails.takeoffApronId])
        if (!t1) {
          this.taskReviewScheduForm.takeoffApronId = '';
        } else {
          this.taskReviewScheduForm.takeoffApronId = this.orderDetails.takeoffApronId;
        }
        //终止点id
        let t2 = _.find(this.landListOption, ['id', this.orderDetails.landApronId])
        if (!t2) {
          this.taskReviewScheduForm.landApronId = '';
        } else {
          this.taskReviewScheduForm.landApronId = this.orderDetails.landApronId;
        }
        // 无人机 taskReviewScheduForm.deviceHardId
        this.taskReviewScheduForm.deviceHardId = this.orderDetails.deviceHardId;
        // 驾驶员
        this.taskReviewScheduForm.flyIds = this.orderDetails.flyIds;
        // console.log('this.taskReviewScheduForm.takeoffApronId', this.taskReviewScheduForm.takeoffApronId);
        // console.log('landListOption', this.landListOption);
        // 样式修改
        this.changeDrawerStyle();
        this.initViewer1();
        this.initViewer2();
      });
    },
    //js 修改抽屉样式
    changeDrawerStyle() {
      // let textarea = document.querySelector('.el-textarea__inner');
      // console.log('textarea', textarea);
      // textarea.style.backgroundColor = '#12354A';
      // textarea.style.border = '1px solid rgba(255,255,255,0.2)';
      // let labels = document.querySelectorAll('.task-schedu .el-form-item__label');
      // let inputDom = document.querySelectorAll('.task-schedu .el-input__inner');
      // let inputIconDom = document.querySelectorAll('.task-schedu .has-icon .el-input__inner');
      // let inputIconImgDom = document.querySelectorAll('.task-schedu .has-icon .el-input__prefix');
      // let itemContentDom = document.querySelectorAll('.task-schedu .el-form-item__content');
      // let itemTextAreaDom = document.querySelectorAll('.order-info .el-textarea__inner');
      // labels.forEach((item) => {
      //   // item.style.color = '#fff';
      //   item.style.color = 'rgba(255, 255, 255, 0.68)';
      // })
      // console.log('inputDom', inputDom);
      // inputDom.forEach((item) => {
      //   item.style.height = '40px';
      //   item.style.background = '#163B53';
      //   item.style.borderRadius = '4px 4px 4px 4px';
      //   item.style.border = '1px solid rgba(255, 255, 255, 0.21)';
      //   item.style.color = '#fff';
      // });
      // itemContentDom.forEach((item) => {
      //   item.style.display = 'flex';
      //   item.style.alignItems = 'center';
      // });
      // inputIconDom.forEach((item) => {
      //   // item.style.paddingLeft = '9%';
      // });
      // console.log('inputIconImgDom', inputIconImgDom);

      // inputIconImgDom.forEach((item) => {
      //   item.style.display = 'none';
      //   item.style.alignItems = 'center';
      // });
      // itemTextAreaDom.forEach((item) => {
      //   item.style.color = '#fff';
      // })
    },
    /**@Description :  ******************************************* 创建第一个地图
     **@Date: 2024-03-19 19:09:49
    */
    initViewer1(flag) {
      viewer1 && entityLine && viewer1.entities.remove(entityLine);
      viewer1 && entityPoint1 && viewer1.entities.remove(entityPoint1);
      viewer1 && entityPoint2 && viewer1.entities.remove(entityPoint2);
      if (viewer1) {
      } else {
        viewer1 = new Cesium.Viewer("orderInfoMapId", {
          animation: false, //是否显示动画控件
          shouldAnimate: true,
          homeButton: false, //是否显示Home按钮
          fullscreenButton: false, //是否显示全屏按钮
          baseLayerPicker: false, //是否显示图层选择控件
          geocoder: false, //是否显示地名查找控件
          timeline: false, //是否显示时间线控件
          sceneModePicker: false, //是否显示投影方式控件
          navigationHelpButton: false, //是否显示帮助信息控件
          infoBox: false, //是否显示点击要素之后显示的信息
          requestRenderMode: true, //启用请求渲染模式
          scene3DOnly: false, //每个几何实例将只能以3D渲染以节省GPU内存
          sceneMode: 3, //初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode
          fullscreenElement: document.body, //全屏时渲染的HTML元素 暂时没发现用处
          selectionIndicator: false,
          animation: false, //是否显示动画控件
          skybox: false, //
          imageryProvider: false,
          // imageryProvider: new Cesium.ArcGisMapServerImageryProvider({
          //   url: "https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer",
          // }),
        });
        viewer1.cesiumWidget.creditContainer.style.display = 'none';
        viewer1.scene.skyBox.show = false //关闭天空盒，否则会显示天空颜色
        // viewer.scene.backgroundColor = Cesium.Color.fromCssColorString("rgba(0, 0, 0, 0)")
        // 添加天地图注记
        let tiandituImageLabelLayer = new Cesium.ImageryLayer(new Cesium.WebMapTileServiceImageryProvider({
          url: "https://{s}.tianditu.com/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=094247775c509d9f6f2856f8fd660b33",
          layer: "tdtAnnoLayer",
          style: "default",
          format: "image/jpeg",
          tileMatrixSetID: "GoogleMapsCompatible",
          subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
          crossorigin: 'anonymous',
        }), {
          show: true,
          alpha: 1
        });

        let imageLayer1 = new Cesium.ImageryLayer(new Cesium.WebMapTileServiceImageryProvider({
          url: "https://{s}.tianditu.gov.cn/img_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE={style}&TILEMATRIXSET={TileMatrixSet}&FORMAT=tiles&TILEMATRIX={TileMatrix}&TILEROW={TileRow}&TILECOL={TileCol}&tk=094247775c509d9f6f2856f8fd660b33",
          layer: "img",
          style: "default",
          format: "tiles",
          tileMatrixSetID: "c",
          credit: new Cesium.Credit("天地图全球影像服务"),
          tilingScheme: new Cesium.GeographicTilingScheme(), //采用经纬度投影
          subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
          tileMatrixLabels: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
          ],
          maximumLevel: 19,
        }));
        let ngp1 = new Cesium.UrlTemplateImageryProvider({
          url: "https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}",
        });
        // viewer1.imageryLayers.add(imageLayer1);
        viewer1.imageryLayers.addImageryProvider(ngp1);
        viewer1.imageryLayers.add(tiandituImageLabelLayer);
        viewer1.scene.globe.depthTestAgainstTerrain = false;
      }

      let fromLng = this.orderDetails.fromLng;
      let fromLat = this.orderDetails.fromLat;
      let toLng = this.orderDetails.toLng;
      let toLat = this.orderDetails.toLat;
      if (!fromLng || !fromLat || !toLng || !toLat) {
        if (flag) {
          this.$message({
            type: 'warning',
            message: '暂无坐标数据'
          });
        }
        return;
      }
      console.log('fromLng, fromLat, toLng, toLat', fromLng, fromLat, toLng, toLat);
      let p1 = new Cesium.Cartesian3.fromDegrees(parseFloat(fromLng), parseFloat(fromLat));
      let p2 = new Cesium.Cartesian3.fromDegrees(parseFloat(toLng), parseFloat(toLat));
      entityLine = new Cesium.Entity({
        polyline: {
          positions: [p1, p2],
          width: 5,
          material: Cesium.Color.GREEN
        }
      });
      entityPoint1 = new Cesium.Entity({
        position: p1,
        billboard: {
          image: 'image/point.png',
          show: true,
          scale: 0.1,
          scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e7, 0.5),
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY, //去掉地形遮挡
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        },
        label: {
          text: "发货地址：" + this.orderDetails.fromAddress,
          fillColor: Cesium.Color.fromCssColorString('#1296DB'),
          outlineColor: new Cesium.Color(255, 255, 255),
          outlineWidth: 2,
          font: '10px',
          scale: 0.8,
          pixelOffset: new Cesium.Cartesian2(0, -35),
          showBackground: false,
          backgroundColor: Cesium.Color.fromCssColorString('rgba(243, 243, 243, 1)'),
          scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e7, 0.5),
          translucencyByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e6, 0.0),
          verticalOrigin: Cesium.VerticalOrigin.TOP,
          disableDepthTestDistance: Number.POSITIVE_INFINITY, //去掉地形遮挡
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        }
      });
      entityPoint2 = new Cesium.Entity({
        position: p2,
        billboard: {
          image: 'image/point-end.png',
          show: true,
          scale: 0.1,
          scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e7, 0.5),
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY, //去掉地形遮挡
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        },
        label: {
          text: "收货地址：" + this.orderDetails.toAddress,
          fillColor: Cesium.Color.fromCssColorString('#E3F104'),
          outlineColor: new Cesium.Color(255, 255, 255),
          outlineWidth: 2,
          font: '10px',
          scale: 0.8,
          pixelOffset: new Cesium.Cartesian2(0, -35),
          showBackground: false,
          backgroundColor: Cesium.Color.fromCssColorString('rgba(243, 243, 243, 1)'),
          scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e7, 0.5),
          translucencyByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e6, 0.0),
          verticalOrigin: Cesium.VerticalOrigin.TOP,
          disableDepthTestDistance: Number.POSITIVE_INFINITY, //去掉地形遮挡
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        }
      });
      viewer1.entities.add(entityLine);
      viewer1.entities.add(entityPoint1);
      viewer1.entities.add(entityPoint2);
      viewer1.flyTo(entityLine);
      // viewer1.camera.flyTo({
      //   destination: Cesium.Cartesian3.fromDegrees(parseFloat(toLat), parseFloat(toLng), 10000),
      // });
    },
    //创建第二个地图
    initViewer2() {
      if (viewer2) {
      } else {
        viewer2 = new Cesium.Viewer("taskScheduMapId", {
          animation: false, //是否显示动画控件
          shouldAnimate: true,
          homeButton: false, //是否显示Home按钮
          fullscreenButton: false, //是否显示全屏按钮
          baseLayerPicker: false, //是否显示图层选择控件
          geocoder: false, //是否显示地名查找控件
          timeline: false, //是否显示时间线控件
          sceneModePicker: false, //是否显示投影方式控件
          navigationHelpButton: false, //是否显示帮助信息控件
          infoBox: false, //是否显示点击要素之后显示的信息
          requestRenderMode: true, //启用请求渲染模式
          scene3DOnly: false, //每个几何实例将只能以3D渲染以节省GPU内存
          sceneMode: 3, //初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode
          fullscreenElement: document.body, //全屏时渲染的HTML元素 暂时没发现用处
          selectionIndicator: false,
          animation: false, //是否显示动画控件
          skybox: false, //
          imageryProvider: false,
          // imageryProvider: new Cesium.ArcGisMapServerImageryProvider({
          //   url: "https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer",
          // }),
        });
        viewer2.cesiumWidget.creditContainer.style.display = 'none';
        viewer2.scene.skyBox.show = false //关闭天空盒，否则会显示天空颜色
        // viewer.scene.backgroundColor = Cesium.Color.fromCssColorString("rgba(0, 0, 0, 0)")
        // 添加天地图注记
        let tiandituImageLabelLayer = new Cesium.ImageryLayer(new Cesium.WebMapTileServiceImageryProvider({
          url: "https://{s}.tianditu.com/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=094247775c509d9f6f2856f8fd660b33",
          layer: "tdtAnnoLayer",
          style: "default",
          format: "image/jpeg",
          tileMatrixSetID: "GoogleMapsCompatible",
          subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
          crossorigin: 'anonymous',
        }), {
          show: true,
          alpha: 1
        });

        let imageLayer1 = new Cesium.ImageryLayer(new Cesium.WebMapTileServiceImageryProvider({
          url: "https://{s}.tianditu.gov.cn/img_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE={style}&TILEMATRIXSET={TileMatrixSet}&FORMAT=tiles&TILEMATRIX={TileMatrix}&TILEROW={TileRow}&TILECOL={TileCol}&tk=094247775c509d9f6f2856f8fd660b33",
          layer: "img",
          style: "default",
          format: "tiles",
          tileMatrixSetID: "c",
          credit: new Cesium.Credit("天地图全球影像服务"),
          tilingScheme: new Cesium.GeographicTilingScheme(), //采用经纬度投影
          subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
          tileMatrixLabels: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
          ],
          maximumLevel: 19,
        }));
        let ngp1 = new Cesium.UrlTemplateImageryProvider({
          url: "https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}",
        });
        // viewer1.imageryLayers.add(imageLayer1);
        viewer2.imageryLayers.addImageryProvider(ngp1);
        viewer2.imageryLayers.add(tiandituImageLabelLayer);
        viewer2.scene.globe.depthTestAgainstTerrain = false;
      }
      viewer2.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(118, 36, 100000),
      });
    },
    //创建任务详情里面订单信息的第一个地图
    initViewer3(flag) {
      console.log('this.orderDetails', this.orderDetails);
      viewer3 && entityLine && viewer3.entities.remove(entityLine);
      viewer3 && entityPoint1 && viewer3.entities.remove(entityPoint1);
      viewer3 && entityPoint2 && viewer3.entities.remove(entityPoint2);
      if (viewer3) {
      } else {
        viewer3 = new Cesium.Viewer("detailOrderInfoMapId", {
          animation: false, //是否显示动画控件
          shouldAnimate: true,
          homeButton: false, //是否显示Home按钮
          fullscreenButton: false, //是否显示全屏按钮
          baseLayerPicker: false, //是否显示图层选择控件
          geocoder: false, //是否显示地名查找控件
          timeline: false, //是否显示时间线控件
          sceneModePicker: false, //是否显示投影方式控件
          navigationHelpButton: false, //是否显示帮助信息控件
          infoBox: false, //是否显示点击要素之后显示的信息
          requestRenderMode: true, //启用请求渲染模式
          scene3DOnly: false, //每个几何实例将只能以3D渲染以节省GPU内存
          sceneMode: 3, //初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode
          fullscreenElement: document.body, //全屏时渲染的HTML元素 暂时没发现用处
          selectionIndicator: false,
          animation: false, //是否显示动画控件
          skybox: false, //
          imageryProvider: false,
          // imageryProvider: new Cesium.ArcGisMapServerImageryProvider({
          //   url: "https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer",
          // }),
        });
        viewer3.cesiumWidget.creditContainer.style.display = 'none';
        viewer3.scene.skyBox.show = false //关闭天空盒，否则会显示天空颜色
        // viewer.scene.backgroundColor = Cesium.Color.fromCssColorString("rgba(0, 0, 0, 0)")
        // 添加天地图注记
        let tiandituImageLabelLayer = new Cesium.ImageryLayer(new Cesium.WebMapTileServiceImageryProvider({
          url: "https://{s}.tianditu.com/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=094247775c509d9f6f2856f8fd660b33",
          layer: "tdtAnnoLayer",
          style: "default",
          format: "image/jpeg",
          tileMatrixSetID: "GoogleMapsCompatible",
          subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
          crossorigin: 'anonymous',
        }), {
          show: true,
          alpha: 1
        });

        let imageLayer1 = new Cesium.ImageryLayer(new Cesium.WebMapTileServiceImageryProvider({
          url: "https://{s}.tianditu.gov.cn/img_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE={style}&TILEMATRIXSET={TileMatrixSet}&FORMAT=tiles&TILEMATRIX={TileMatrix}&TILEROW={TileRow}&TILECOL={TileCol}&tk=094247775c509d9f6f2856f8fd660b33",
          layer: "img",
          style: "default",
          format: "tiles",
          tileMatrixSetID: "c",
          credit: new Cesium.Credit("天地图全球影像服务"),
          tilingScheme: new Cesium.GeographicTilingScheme(), //采用经纬度投影
          subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
          tileMatrixLabels: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
          ],
          maximumLevel: 19,
        }));
        let ngp1 = new Cesium.UrlTemplateImageryProvider({
          url: "https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}",
        });
        // viewer1.imageryLayers.add(imageLayer1);
        viewer3.imageryLayers.addImageryProvider(ngp1);
        viewer3.imageryLayers.add(tiandituImageLabelLayer);
        viewer3.scene.globe.depthTestAgainstTerrain = false;
      }
      let fromLng = this.orderDetails.fromLng;
      let fromLat = this.orderDetails.fromLat;
      let toLng = this.orderDetails.toLng;
      let toLat = this.orderDetails.toLat;
      console.log('this.orderDetails', this.orderDetails);
      console.log('fromLng, fromLat, toLng, toLat', fromLng, fromLat, toLng, toLat);
      if (!fromLng || !fromLat || !toLng || !toLat) {
        if (flag) {
          this.$message({
            type: 'warning',
            message: '暂无坐标数据'
          });
        }
        return;
      }
      console.log('fromLng, fromLat, toLng, toLat', fromLng, fromLat, toLng, toLat);
      let p1 = new Cesium.Cartesian3.fromDegrees(parseFloat(fromLng), parseFloat(fromLat));
      let p2 = new Cesium.Cartesian3.fromDegrees(parseFloat(toLng), parseFloat(toLat));
      entityLine = new Cesium.Entity({
        polyline: {
          positions: [p1, p2],
          width: 5,
          material: Cesium.Color.GREEN
        }
      });
      entityPoint1 = new Cesium.Entity({
        position: p1,
        billboard: {
          image: 'image/point.png',
          show: true,
          scale: 0.1,
          scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e7, 0.5),
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY, //去掉地形遮挡
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        },
        label: {
          text: "发货地址：" + this.orderDetails.fromAddress,
          fillColor: Cesium.Color.fromCssColorString('#1296DB'),
          outlineColor: new Cesium.Color(255, 255, 255),
          outlineWidth: 2,
          font: '10px',
          scale: 0.8,
          pixelOffset: new Cesium.Cartesian2(0, -35),
          showBackground: false,
          backgroundColor: Cesium.Color.fromCssColorString('rgba(243, 243, 243, 1)'),
          scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e7, 0.5),
          translucencyByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e6, 0.0),
          verticalOrigin: Cesium.VerticalOrigin.TOP,
          disableDepthTestDistance: Number.POSITIVE_INFINITY, //去掉地形遮挡
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        }
      });
      entityPoint2 = new Cesium.Entity({
        position: p2,
        billboard: {
          image: 'image/point-end.png',
          show: true,
          scale: 0.1,
          scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e7, 0.5),
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY, //去掉地形遮挡
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        },
        label: {
          text: "收货地址：" + this.orderDetails.toAddress,
          fillColor: Cesium.Color.fromCssColorString('#E3F104'),
          outlineColor: new Cesium.Color(255, 255, 255),
          outlineWidth: 2,
          font: '10px',
          scale: 0.8,
          pixelOffset: new Cesium.Cartesian2(0, -35),
          showBackground: false,
          backgroundColor: Cesium.Color.fromCssColorString('rgba(243, 243, 243, 1)'),
          scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e7, 0.5),
          translucencyByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e6, 0.0),
          verticalOrigin: Cesium.VerticalOrigin.TOP,
          disableDepthTestDistance: Number.POSITIVE_INFINITY, //去掉地形遮挡
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        }
      });
      viewer3.entities.add(entityLine);
      viewer3.entities.add(entityPoint1);
      viewer3.entities.add(entityPoint2);
      viewer3.flyTo(entityLine);
      // viewer3.camera.flyTo({
      //   destination: Cesium.Cartesian3.fromDegrees(parseFloat(fromLat), parseFloat(toLng), 10000),
      // });
    },
    /**@Description :  ******************************************* 弹出任务详情抽屉
     **@Date: 2024-03-16 13:04:13
    */
    async handleDetail(index, row) {
      this.drawerTaskDetail = true;
      this.orderInfo = row;
      let data = {};
      data.deliveryId = row.deliveryId;
      console.log('data', data);
      let res = await API.TASKORDER.getOrderDetails(data);
      console.log('任务订单详情', res);
      console.log('任务订单列表数据', this.orderInfo);
      this.orderDetails = res;
      this.$nextTick(() => {
        // 样式修改
        this.changeDrawerStyle();
        this.initViewer3();
      });
    },
    /**@Description :  ******************************************* 任务订单列表
     **@Date: 2024-03-16 09:24:44
    */
    async taskListPage() {
      // console.log('this.formInline', this.formInline);
      if (!this.formInline.rangTime) {
        this.formInline.beginTime = '';
        this.formInline.endTime = '';
      } else {
        this.formInline.beginTime = dayjs(this.formInline.rangTime[0]).format('YYYY-MM-DD HH:mm:ss');
        this.formInline.endTime = dayjs(this.formInline.rangTime[1]).format('YYYY-MM-DD HH:mm:ss');
      }
      if (this.formInline.status == '全部') {
        this.formInline.status = ''
      }
      // delete this.formInline.rangTime;
      // console.log('this.formInline', this.formInline);

      // 数据参数处理
      let data = {};
      if (this.formInline.beginTime) {
        data.beginTime = this.formInline.beginTime;
      }
      if (this.formInline.endTime) {
        data.endTime = this.formInline.endTime;
      }
      if (this.formInline.status) {
        data.status = this.formInline.status;
      }
      data.keywords = this.formInline.keywords;
      data.pageNo = this.formInline.pageNo;
      data.pageSize = this.formInline.pageSize;
      data.deviceName = this.formInline.deviceName || '';
      // console.log('data', data);
      let res = await API.TASKORDER.taskListPage(data);
      // console.log('分页任务列表', res);
      this.tableData = res.records;
      this.mainTableTotal = res.total;
    },
    /**@Description :  ******************************************* 起降场下拉列表 web_landList
     **@Date: 2024-03-16 13:50:50
    */
    async web_landList() {
      let res = await API.TASKORDER.web_landList();
      console.log('起降场下拉列表', res);
      this.landListOption = res;
      res.forEach(item => {
        this.landList.push({
          'value': item.id,
          'text': item.name

        })
      })
    },
    /**@Description :  ******************************************* 任务航线选择
     **@Date: 2024-03-16 14:03:41
    */
    async handleTaskAirLineChange(v) {
      if (v) {
        if (!this.taskReviewScheduForm.takeoffApronId || !this.taskReviewScheduForm.landApronId) {
          this.$message({
            type: 'warning',
            message: '请先选择起降场'
          });
          return;
        }
        let params = {
          startLandingFieldId: this.taskReviewScheduForm.takeoffApronId,
          endLandingFieldId: this.taskReviewScheduForm.landApronId,
        }
        console.log('params', params);

        let res = await API.TASKORDER.getFlightLineList(params);
        console.log('任务航线下拉列表', res);
        this.$nextTick(() => {
          this.airLineListOption = res;
        });

      }
    },
    /**@Description :  ******************************************* 执飞，跳转到无人机界面
     **@Date: 2024-03-16 14:16:06
    */
    handleEcexuteFly(index, row) {
      console.log('row', row);
      // console.log('this', this.de);
      let params = `?taskId=${row.taskId}&deliveryId=${row.deliveryId}`
      console.log('params', params);
      window.open(`#/fckernel_uu${params}`, "_self");
      this.$router.go(0);
    },
    /**@Description :  ******************************************* 获取无人机列表
     **@Date: 2024-03-16 14:56:06
    */
    async getDeviceListForLogistics() {
      let res = await API.TASKORDER.getDeviceListForLogistics();
      console.log('获取无人机列表', res);
      this.deviceListForLogisticsListOption = res;
    },
    /**@Description :  ******************************************* 审核通过 applyStatus 1， 取消 2
     **@Date: 2024-03-16 20:22:12
    */
    async handleApprove() {
      console.log('taskReviewScheduForm', this.taskReviewScheduForm);
      console.log('d', this.orderDetails);
      console.log('d1', this.orderInfo);
      let data = {};
      data.deliveryId = String(this.orderDetails.deliveryId);
      data.takeoffApronId = String(this.taskReviewScheduForm.takeoffApronId);
      data.landApronId = String(this.taskReviewScheduForm.landApronId);
      data.flightLineId = this.taskReviewScheduForm.flightLineId;
      data.deviceHardId = this.taskReviewScheduForm.deviceHardId || '';
      data.deviceId = this.orderDetails.deviceId;
      data.flyIds = this.taskReviewScheduForm.flyIds;
      data.applyStatus = 1;
      this.deviceListForLogisticsListOption.forEach((item) => {
        // console.log('item', item.deviceHardId);
        // console.log('item', item);
        let a = String(item.deviceHardId);
        let b = this.taskReviewScheduForm.deviceHardId
        // console.log('a, b', a, b);
        if (a == b) {
          // console.log('xxxxxxxxxxxx');
          data.deviceId = item.id;
        }
      });

      /**
       * 起始点
       * 终止点
       * 任务航线
       * 无人机
       * **/
      console.log('data', data);

      if (!data.takeoffApronId) {
        this.$message({
          type: 'warning',
          message: '请选择起始点'
        })
        return;
      }
      if (!data.landApronId) {
        this.$message({
          type: 'warning',
          message: '请选择终止点'
        })
        return;
      }
      if (!data.flightLineId) {
        this.$message({
          type: 'warning',
          message: '请选择任务航线'
        })
        return;
      }
      if (!data.deviceHardId) {
        this.$message({
          type: 'warning',
          message: '请选择无人机'
        })
        return;
      }

      console.log('data审核提交数据', data);

      let res = await API.TASKORDER.taskAudit(data);
      this.$refs['taskReviewScheduForm'].resetFields();
      this.drawerTaskReview = false;
      this.taskListPage();
    },
    /**@Description :  ******************************************* 取消订单 applyStatus 1， 取消 2
     **@Date: 2024-03-16 20:40:33
    */
    async handleCancel() {
      console.log('taskReviewScheduForm', this.taskReviewScheduForm);
      console.log('d', this.orderDetails);
      console.log('d1', this.orderInfo);
      let data = {};
      data.deliveryId = String(this.orderDetails.deliveryId);
      // data.takeoffApronId = String(this.taskReviewScheduForm.takeoffApronId);
      // data.landApronId = String(this.taskReviewScheduForm.landApronId);
      // data.flightLineId = this.taskReviewScheduForm.flightLineId;
      // data.deviceHardId = String(this.taskReviewScheduForm.deviceHardId);
      // data.deviceId = this.orderDetails.deviceId;
      // data.flyIds = this.orderDetails.flylds;
      data.applyStatus = 2;
      // this.deviceListForLogisticsListOption.forEach((item) => {
      //   console.log('item', item.deviceHardId);
      //   console.log('item', item);
      //   let a = String(item.deviceHardId);
      //   let b = this.taskReviewScheduForm.deviceHardId
      //   console.log('a, b', a, b);
      //   if (a == b) {
      //     console.log('xxxxxxxxxxxx');
      //     data.deviceId = item.id;
      //   }
      // });
      let res = await API.TASKORDER.taskAudit(data);
      console.log('取消审核', res);

      this.$refs['taskReviewScheduForm'].resetFields();
      this.drawerTaskReview = false;
      this.taskListPage();
    },
    /**@Description :  ******************************************* 获取飞手
     **@Date: 2024-03-16 21:22:48
    */
    async getFlyerList() {
      let res = await API.USER.DrdPilot();
      console.log('飞手', res);
      this.flyerList = res;
    },
    /**@Description :  ******************************************* 
     **@Date: 2024-03-18 16:03:30
    */
    previewAirLine() {
      // 获取到数据后，调用预览方法
      // console.log('任务审核表单1', this.takeoffApronSelectedData);
      // console.log('任务审核表单2', this.landApronIdSelectedData);
      // console.log('任务审核表单3', this.flightLineSelectedData);
      if (!this.flightLineSelectedData?.flightCourseJson) {
        this.$message({
          type: 'warning',
          message: '暂无航线数据'
        });
        return;
      }
      let flightCourseJson = JSON.parse(this.flightLineSelectedData.flightCourseJson);
      // console.log('flightCourseJson', flightCourseJson);

      entityArray.forEach((item) => {
        viewer2 && viewer2.entities.remove(item);
      });
      entityArray = [];
      let pointList = [];
      for (let i = 0; i < flightCourseJson.length; i++) {
        let item1 = flightCourseJson[i];
        let p1 = new Cesium.Cartesian3.fromDegrees(parseFloat(item1.lon), parseFloat(item1.lat));
        pointList.push(p1);
        console.log('p1, p2', p1);
        let option = {
          position: p1,
          billboard: {
            image: 'image/point.png',
            show: true,
            scale: 0.1,
            scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e7, 0.5),
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            disableDepthTestDistance: Number.POSITIVE_INFINITY, //去掉地形遮挡
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
          },
        }
        if (i == flightCourseJson.length - 1) {
          option.billboard.image = 'image/point-end.png';
          option.label = {
            text: "收货地址：" + this.landApronIdSelectedData.name,
            fillColor: Cesium.Color.fromCssColorString('#1296DB'),
            outlineColor: new Cesium.Color(255, 255, 255),
            outlineWidth: 2,
            font: '10px',
            scale: 0.8,
            pixelOffset: new Cesium.Cartesian2(0, -35),
            showBackground: false,
            backgroundColor: Cesium.Color.fromCssColorString('rgba(243, 243, 243, 1)'),
            scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e7, 0.5),
            translucencyByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e6, 0.0),
            verticalOrigin: Cesium.VerticalOrigin.TOP,
            disableDepthTestDistance: Number.POSITIVE_INFINITY, //去掉地形遮挡
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
          }
        }
        if (i == 0) {
          option.label = {
            text: "发货地址：" + this.takeoffApronSelectedData.name,
            fillColor: Cesium.Color.fromCssColorString('#1296DB'),
            outlineColor: new Cesium.Color(255, 255, 255),
            outlineWidth: 2,
            font: '10px',
            scale: 0.8,
            pixelOffset: new Cesium.Cartesian2(0, -35),
            showBackground: false,
            backgroundColor: Cesium.Color.fromCssColorString('rgba(243, 243, 243, 1)'),
            scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e7, 0.5),
            translucencyByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e6, 0.0),
            verticalOrigin: Cesium.VerticalOrigin.TOP,
            disableDepthTestDistance: Number.POSITIVE_INFINITY, //去掉地形遮挡
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
          }
        }
        let entity = viewer2.entities.add(option);
        entityArray.push(entity);
      }

      let entityLine = new Cesium.Entity({
        polyline: {
          positions: pointList,
          width: 5,
          material: Cesium.Color.GREEN
        }
      });
      viewer2.entities.add(entityLine);
      entityArray.push(entityLine);
      viewer2.flyTo(entityLine);

    },
    handleTakeoffApron(v) {
      console.log('起始点', v);
      this.takeoffApronSelectedData = v;
    },
    handlelandApron(v) {
      console.log('终止点', v);
      //任务调度终止点选择内容
      this.landApronIdSelectedData = v;
    },
    handleFlightLine(v) {
      console.log('任务航线', v);
      //任务调度任务航线选择内容
      this.flightLineSelectedData = v;
    },
    /**@Description :  ******************************************* 分页-页码变化
     **@Date: 2024-03-18 17:28:10
    */
    handleCurrentChange(v) {
      // console.log('第几页', v);
      this.formInline.pageNo = v;
      this.taskListPage();
    },
    /**@Description :  ******************************************* 分页，每一页数据条数变化
     **@Date: 2024-03-18 17:30:56
    */
    handleSizeChange(v) {
      // console.log('每一页有多少条数据', v);
      // 页码从第一页开始
      this.formInline.pageNo = 1;
      this.formInline.pageSize = v;
      this.taskListPage();
    },
    /**@Description :  ******************************************* 10s刷新一次数据列表 和 统计数据
     **@Date: 2024-03-19 11:02:45
    */
    refreshTable() {
      let now = (new Date()).getTime();
      if (now - refreshTime > 10000) {
        this.taskListPage();
        this.getStatusCount();
        refreshTime = now;
      }
      requestAnimationFrame(this.refreshTable);
    },
    /**@Description :  ******************************************* 如果起始点或者终止点修改，则任务航线也必须重新选择，即在这里设置任务航线id为空
     **@Date: 2024-03-21 15:18:43
    */
    async handleTakeoffAndLandApronIdChange() {
      // this.taskReviewScheduForm.flightLineId = '';
      if (this.taskReviewScheduForm.flightLineId) {
        let params = {};
        params.startLandingFieldId = String(this.taskReviewScheduForm.takeoffApronId);
        params.endLandingFieldId = String(this.taskReviewScheduForm.landApronId);
        // console.log('data', data);
        // console.log('this.taskReviewScheduForm.flightLineId', this.taskReviewScheduForm.flightLineId);
        let res = await API.TASKORDER.getFlightLineList(params);
        // console.log('res', res);
        let flag = res.some((item) => {
          String(item.id) == String(this.taskReviewScheduForm.flightLineId);
        });
        if (!flag) {
          this.taskReviewScheduForm.flightLineId = '';
        }
      }
    },
    /**@Description :  ******************************************* 表格多选
     **@Date: 2024-05-30 14:42:39
    */
    handleMultipleTableSelectionChange(v) {
      console.log('vvvvvvvv', v);

      this.multipleSelection = v;
    },
    /**@Description :  ******************************************* 删除订单点击事件
     **@Date: 2024-05-30 14:43:35
    */
    handleDelDevByIds() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          type: 'warning',
          message: '请选择任务订单'
        });
        return;
      }
      this.handleDelDevByIdsSubmit();
    },
    /**@Description :  ******************************************* 删除订单
     **@Date: 2024-05-30 14:46:30
    */
    async handleDelDevByIdsSubmit() {
      this.$confirm('请确认是否进行删除操作', '', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        customClass: 'uav_controlPane',
        showClose: false,
      })
        .then(async () => {
          let deliveryIds = [];
          this.multipleSelection.forEach((v) => {
            deliveryIds.push(v.deliveryId);
          });
          let res = await API.TASKORDER.delDevByIdsSubmit(deliveryIds.join(','));
          console.log('删除返回值', res);
          if (res.status == 1) {
            this.taskListPage();
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          })
        })
    }
  },
};
</script>

<style lang="scss" scoped>
.page-observe-fckernel-uav {
  height: 100%;
  width: 100%;

  .side-bar {
    position: absolute;
    top: 64px;
    left: 0;
    z-index: 100;
  }

  .task-main {
    width: calc(100% - 80px);
    height: calc(100% - 64px);
    background-color: #1f2030;
    margin-top: 64px;
    margin-left: 80px;
    z-index: 10;
    position: relative;
    box-sizing: border-box;
    padding: 20px;

    .info-c {
      width: 100%;
      height: 122px;
      // background-color: rgba(0, 100, 0, 0);
      // border: 1px solid #ff0000;
      background-color: #222e4c;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0 auto;

      .item {
        // width: 20%;
        // height: 65%;
        // width: 276px;
        width: 209px;
        height: 84px;
        // border: 0 solid yellow;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 20px;
        background-image: url("~@/assets/img/i144.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        padding: 0 25px 0 20px;

        .l {
          // background-color: yellow;
          width: 30%;
          height: 65%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;

          .n {
            // width: 42px;
            // height: 14px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.88);
            line-height: 14px;
            text-align: left;
            font-style: normal;
            text-transform: none;
          }

          .v {
            // width: 32px;
            // height: 36px;
            font-family: Arial, Arial;
            font-weight: bold;
            font-size: 28px;
            color: #00f5ff;
            line-height: 39px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            margin-top: 5px;
          }
        }

        .r {
          width: 30%;
          height: 65%;

          .i {
            width: 56px;
            height: 56px;
            background: linear-gradient(
              180deg,
              rgba(255, 154, 14, 0.07) 0%,
              rgba(255, 180, 75, 0.73) 79%,
              #ff9a0e 100%
            );
            box-shadow: inset 0px 4px 12px 0px rgba(255, 236, 187, 0.62);
            border-radius: 12px 12px 12px 12px;
          }
        }
      }

      .item:nth-child(1) {
        margin-left: 20px;

        .r {
          .i {
            background-image: url("~@/assets/img/i148.png");
          }
        }
      }

      .item:nth-child(2) {
        .r {
          .i {
            background-image: url("~@/assets/img/i145.png");
          }
        }
      }

      .item:nth-child(3) {
        .r {
          .i {
            background-image: url("~@/assets/img/i146.png");
          }
        }
      }

      .item:nth-child(4) {
        .r {
          .i {
            background-image: url("~@/assets/img/i147.png");
          }
        }
      }
    }

    .search-c {
      width: 100%;
      height: 10%;
      background-color: #222e4c;
      margin: 0 auto;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      box-sizing: border-box;
      padding: 0 10px 0 20px;

      .demo-form-inline {
        justify-content: flex-start !important;
        // background-color: yellow;
        width: 100%;
      }

      .v {
        width: 76%;
        // background-color: #00F5FF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // border:1px solid #ff0000;
      }

      .o-1 {
        width: 12%;
        height: 100%;
        // margin-left: -3%;
        // border:1px solid #ff0000;

        // background-color: #FF9A0E;
        .r-btn {
          width: 56px;
          height: 40px;
          background: #495e93;
          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5),
            inset 0px 1px 0px 0px rgba(255, 255, 255, 0.29);
          border-radius: 4px 4px 4px 4px;
          border: 0;
        }

        .s-btn {
          width: 56px;
          height: 40px;
          background: #2b72ea;
          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5),
            inset 0px 1px 0px 0px rgba(255, 255, 255, 0.29);
          border-radius: 4px 4px 4px 4px;
          border: 0;
        }
      }

      .o-2 {
        width: 12%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // margin-left: 8%;
        // border:1px solid #ff0000;

        // background-color: #00F5FF;
        .d-btn {
          width: 70px;
          height: 40px;
          background: rgba(255, 42, 42, 0.14);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid rgba(255, 73, 73, 0.52);
        }

        .c-btn {
          width: 120px;
          height: 40px;
          background: #2b72ea;
          border-radius: 4px 4px 4px 4px;
          border: 0;
        }
      }

      /deep/ .el-form--inline {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      /deep/ .el-form-item {
        margin-bottom: 0;
      }

      /deep/ .el-input__inner {
        // width: 150px;
        height: 40px;
        background: #19243e;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid rgba(255, 255, 255, 0.21);
        color: #fff;
      }

      /deep/ .el-range-input {
        background: #19243e;
      }

      /deep/ .el-form-item__label {
        color: #ffffff;
      }

      /deep/ .el-range-separator {
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      /deep/ .el-range-input {
        color: #ffffff;
      }
    }

    .table-c {
      width: 100%;
      height: 75%;
      background-color: #222e4c;
      margin: 0 auto;
      margin-top: 0px;
      padding: 0 10px;
      box-sizing: border-box;

      .h {
        width: 100%;
        height: 40px;
        // background-color: #C280FF;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .r-table {
        overflow-y: auto;
        height: auto;
        max-height: calc(100% - 70px);
        border-radius: 4px;

        .op-name {
          color: #0bf0ff;
        }

        .taskStatus {
          width: 62px;
          height: 28px;
          border-radius: 23px 23px 23px 23px;
          box-sizing: border-box;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }

        // 待审核
        .taskStatus-2 {
          background: rgba(255, 184, 0, 0.13);
          color: #ff9a0e;
        }

        // 待派发
        .taskStatus-1 {
          background: rgba(255, 214, 0, 0.13);
          color: #ffde6b;
        }

        // 待执行
        .taskStatus-0 {
          background: rgba(0, 255, 163, 0.13);
          color: #19e210;
        }

        // 执行中
        .taskStatus-3 {
          background: rgba(0, 255, 194, 0.13);
          color: #00ffc2;
        }

        // 执行完成
        .taskStatus-11 {
          background: rgba(0, 194, 255, 0.13);
          color: #4ecaff;
        }

        // 待收货
        .taskStatus-5 {
          background: rgba(230, 255, 74, 0.13);
          color: #e6ff4a;
        }

        // 已取消
        .taskStatus-99 {
          background: rgba(255, 254, 249, 0.12);
          color: rgba(255, 255, 255, 0.76) !important;
        }

        /deep/ .el-table th.el-table__cell {
          background-color: #2d446a;
          color: #ffffff;
          font-weight: 600;
          font-size: 14px;
        }

        /deep/ .el-table__row td.el-table__cell {
          // background-color: #2D446A;
          color: rgba(255, 255, 255, 0.88) !important;
          font-weight: 400;
          font-size: 14px;
        }

        /deep/ .el-table__row:has(.is-checked) {
          background: linear-gradient(
              270deg,
              rgba(13, 74, 236, 0.1) 0%,
              rgba(113, 255, 238, 0.26) 100%
            ),
            rgba(99, 177, 245, 0.1);
        }

        /deep/ .el-table__row:hover {
          background: linear-gradient(
              270deg,
              rgba(13, 74, 236, 0.1) 0%,
              rgba(113, 255, 238, 0.26) 100%
            ),
            rgba(99, 177, 245, 0.1);
        }

        /* css  鼠标悬浮时 */
        /deep/ .el-table tbody tr:hover > td {
          background-color: rgba(65, 111, 180, 0) !important;
          // color: #ffffff;
        }

        /deep/ .el-table--border::after,
        .el-table--group::after,
        .el-table::before {
          background-color: transparent !important;
        }

        /deep/ .el-table td.el-table__cell,
        .el-table th.el-table__cell.is-leaf {
          border-bottom: 0 !important;
        }

        /deep/ .el-table th.el-table__cell.is-leaf,
        .el-table td.el-table__cell {
          border-bottom: 0 !important;
        }

        /deep/ .el-checkbox__inner {
          background-color: transparent !important;
          border: 1px solid #00f5ff;
          width: 16px;
          height: 16px;
          border-radius: 2px 2px 2px 2px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        /deep/ .el-table__empty-block {
          background-color: #263959 !important;
        }
      }

      .p {
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        /deep/ .number {
          background: #263959;
          border-radius: 2px 2px 2px 2px;
          color: rgba(255, 255, 255, 0.65);
        }

        /deep/ .btn-next {
          background: #263959;
          color: rgba(255, 255, 255, 0.65);
        }

        /deep/ .btn-prev {
          background: #263959;
          color: rgba(255, 255, 255, 0.65);
        }

        /deep/ .btn-quickprev {
          background: #263959;
          color: rgba(255, 255, 255, 0.65);
        }

        /deep/ .btn-quicknext {
          background: #263959;
          color: rgba(255, 255, 255, 0.65);
        }

        /deep/ .active {
          background-color: #409eff !important;
        }

        /deep/ .el-input__inner {
          // width: 150px;
          // height: 40px;
          background: #19243e;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid rgba(255, 255, 255, 0.21);
          color: #fff;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.el-card__header {
  border-bottom: 1px solid rgba(163, 177, 187, 0.8);
}
.r-table {
  .oddRow {
    background-color: #27385a !important;
  }

  .evenRow {
    background-color: #2d446a !important;
  }
}

.drawer-task {
  // background-color: #27385A;
  background-color: #0a293a;

  .header {
    height: 54px;
    background: #224d68;
    border-radius: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .l {
      height: 100%;
      width: 30%;
      // border: 1px solid #ff0000;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      box-sizing: border-box;
      padding: 0 20px;

      img {
        width: 16px;
        height: 16px;
      }

      span {
        width: 64px;
        height: 22px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-left: 10px;
      }
    }

    .r {
      height: 100%;
      width: 20%;
      // border: 1px solid #ff0000;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-sizing: border-box;
      padding: 0 10px;

      img {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }

  // 新建配送订单任务的表单
  .create-task-body {
    width: 100%;
    height: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 18px;
    padding: 0 12px;
    box-sizing: border-box;
    background-color: #0a293a;

    //   .create-task {
    //     width: 100%;
    //     height: 100%;
    //     position: relative;

    .card {
      display: flex;
      // align-items: center;
      flex-direction: column;
      // justify-content: space-between;
      box-sizing: border-box;
      // padding-bottom: 10px;
      margin: 16px 20px 10px 20px;
      background-color: #163b53;
      border-radius: 4px;
      color: #fff;

      //   .sub-header {
      //     width: 100%;
      //     height: 28px;
      //     border-radius: 4px;
      //     padding-left: 15px;
      //     background: #224d68;
      //     line-height: center;
      //     // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      //   }
      // }
    }
  }

  .body {
    width: 100%;
    height: calc(100% - 110px);
    // border: 1px solid #ff0000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px 10px;
    box-sizing: border-box;
    background-color: #0a293a;

    .order-info,
    .task-schedu {
      width: 100%;
      height: 44%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // border-bottom: 1px solid #9b4b4b;
      background-color: #163b53;
      border-radius: 4px 4px 4px 4px;
      box-sizing: border-box;
      padding: 10px 20px;
      flex-direction: column;

      .h {
        width: 100%;
        height: 40px;
        border-radius: 0px 0px 0px 0px;
        // border: 1px solid #ff0000;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-image: url("~@/assets/img/i153.png");
        background-repeat: no-repeat;
        background-size: 100% 1%;
        background-position: 0px 38px;

        img {
          width: 3px;
          height: 16px;
        }

        span {
          width: 64px;
          height: 22px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #ffffff;
          line-height: 19px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-left: 5px;
        }
      }

      .b {
        width: 100%;
        height: calc(100% - 40px);
        border-radius: 0px 0px 0px 0px;
        // border: 1px solid #ff0000;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;

        .ll {
          width: 70%;
          height: 100%;

          // border: 1px solid #ff0000;
          .info-text {
            color: #ffffff;
          }

          .row {
            width: 100%;
            height: 18%;
            display: flex;
            align-items: center;
            justify-content: center;

            .label {
              // width: 60px;
              // height: 17px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: rgba(255, 255, 255, 0.68);
              line-height: 14px;
              text-align: left;
              font-style: normal;
              text-transform: none;
            }

            .text-v {
              // width: 56px;
              // height: 20px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #ffffff;
              line-height: 16px;
              text-align: right;
              font-style: normal;
              text-transform: none;
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
          }

          .row-remark {
            height: 25%;
            // border: 1px solid #ff0000;

            /deep/ .el-textarea__inner {
              background: rgba(10, 41, 58, 0.36) !important;
              border-radius: 4px 4px 4px 4px !important;
              border: 1px solid rgba(255, 255, 255, 0.2) !important;
            }
          }
        }

        .m {
          width: 28%;
          height: 100%;
          // border: 1px solid #ff0000;

          #orderInfoMapId,
          #taskScheduMapId,
          #detailOrderInfoMapId {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .task-schedu {
      height: 55%;

      .line {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        height: 100%;

        img {
          width: 24px;
          height: 24px;
        }

        .btn-p {
          width: 44px;
          height: 29px;
          background: #495e93;
          border-radius: 4px 4px 4px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          line-height: 14px;
          text-align: center;
          font-style: normal;
          text-transform: none;
          border: 0;
        }

        .btn-d {
          width: 68px;
          height: 29px;
          background: rgba(58, 212, 224, 0.08);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid rgba(58, 212, 224, 0.55);
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #3ad4e0;
          line-height: 14px;
          text-align: center;
          font-style: normal;
          text-transform: none;
          // border: 0;
        }

        .btn-v {
          width: 56px;
          height: 20px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: rgba(58, 212, 224, 0.91);
          line-height: 16px;
          text-align: right;
          font-style: normal;
          text-transform: none;
          background-color: transparent;
          border: 0;
        }
      }
    }

    .detail-task-schedu {
      .f {
        width: 66px;
        height: 28px;
        // background: rgba(0, 255, 163, 0.13);
        border-radius: 4px 4px 4px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;

        span {
          // font-family: PingFang SC, PingFang SC;
          // font-weight: 400;
          // font-size: 14px;
          // color: #19E210;
          // text-align: center;
          // font-style: normal;
          // text-transform: none;
          // display: flex;
          // align-items: center;
          // justify-content: center;
        }
      }

      .line-1 {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img {
          width: 40px;
          margin-top: -22px;
        }
      }

      .b {
        .ll {
          // border: 1px solid #ff0000;
          width: 35% !important;
        }

        .m {
          width: 62%;
          // border: 1px solid #ff0000;

          .o-l-g {
            width: 100%;
            height: 100%;
            background-color: #133144;
            box-sizing: border-box;
            padding: 10px;

            .h {
              width: 100%;
              height: 10%;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #ffffff;
              line-height: 16px;
              text-align: left;
              font-style: normal;
              text-transform: none;
              letter-spacing: 2px;
            }

            .c-h {
              width: 100%;
              height: 10%;
              // border: 1px solid #ce9a9a;

              .h-r {
                width: 100%;
                height: 100%;
                // border: 1px solid #ff0000;
                background: rgba(99, 177, 245, 0.1);

                .h-c {
                  // border: 1px solid #ff0000;
                  // width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .h-t {
                    color: #ffffff;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 500;
                    font-size: 14px;
                    text-align: left;
                    font-style: normal;
                    text-transform: none;
                    width: 100% !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }
            }

            .c-b {
              width: 100%;
              height: 80%;
              // border: 1px solid #ff0000;
              overflow: auto;

              .h-r {
                width: 100%;
                height: 15%;
                // border: 1px solid #ff0000;
                background: rgba(99, 177, 245, 0.1);

                .h-c {
                  // border: 1px solid #ff0000;
                  // width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .h-t {
                    color: #ffffff;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 500;
                    font-size: 14px;
                    text-align: left;
                    font-style: normal;
                    text-transform: none;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }

              .r-odd {
                background-color: #1b3e56;
              }

              .r-ever {
                background-color: #133144;
              }
            }
          }
        }
      }

      .taskStatus {
        // width: 62px;
        // height: 28px;
        // border-radius: 23px 23px 23px 23px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }

      .taskStatus-label {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
      }

      // 待审核
      .taskStatus-2 {
        background: rgba(255, 184, 0, 0.13);
        color: #ff9a0e;
      }

      // 待派发
      .taskStatus-1 {
        background: rgba(255, 214, 0, 0.13);
        color: #ffde6b;
      }

      // 待执行
      .taskStatus-0 {
        background: rgba(0, 255, 163, 0.13);
        color: #19e210;
      }

      // 执行中
      .taskStatus-3 {
        background: rgba(0, 255, 194, 0.13);
        color: #00ffc2;
      }

      // 执行完成
      .taskStatus-11 {
        background: rgba(0, 194, 255, 0.13);
        color: #4ecaff;
      }

      // 待收货
      .taskStatus-5 {
        background: rgba(230, 255, 74, 0.13);
        color: #e6ff4a;
      }

      // 已取消
      .taskStatus-99 {
        background: rgba(255, 254, 249, 0.12);
        color: rgba(255, 255, 255, 0.76) !important;
      }
    }
  }

  .foot {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 0 20px;

    .c,
    .p {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 40px;
      border: 0;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      line-height: 16px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      border-radius: 4px 4px 4px 4px;
    }

    .c {
      background: #133d54;
    }

    .p {
      background: #007aff;
    }
  }
}
</style>
